import React from "react";
export interface ICardProps {
  className?: string;
  children: React.ReactNode;
}

const Card: React.FC<ICardProps> = ({ children, className }) => {
  return <div className={`card ${className}`}>{children}</div>;
};

export default Card;

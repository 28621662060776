import { faPercentage, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import rateShopperEndPoint from "../../Apis/rateShopperEndPoint";
import Card from "../../Components/card/Card";
import useModal from "../../Components/customModal/useModal";
import InputSelectField from "../../Components/inputtextfield/InputSelectField";
import Loader from "../../Components/loader/Loader";
import { RootState } from "../../Redux/store";
import { CATCH_MESSAGE } from "../../UtilityFunctions";
export interface ICompititorModalProps {
  hide: any;
  selectedPlans: string;
  selectedOccupancy: string;
  planOptn: any[];
  occupancy: any[];
}

const CompititorModal: React.FC<ICompititorModalProps> = ({
  hide,
  selectedPlans,
  selectedOccupancy,
  planOptn,
  occupancy,
}) => {
  const { admin_id, auth_token, hotel_id } = useSelector(
    (state: RootState) => state.auth
  );
  // const { isShowing, toggle } = useModal();
  const { full_date } = useSelector((state: RootState) => state.dashboardModal);
  const [modalDate, setModalDate] = useState<any>({
    full_date: "",
    month: "",
    year: "",
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedModalOccupancy, setSelectedModalOccupancy] =
    useState<any>(selectedOccupancy);
  const [selectedMealPlans, setSelectedMealPlans] =
    useState<any>(selectedPlans);
  const [competitorData, setCompetitorData] = useState<any[]>([]);
  const [websiteSources, setWebsiteSources] = useState<any[]>([]);
  useEffect(() => {
    let clicked_date = moment(full_date).format("YYYY-MM-DD");
    let month_value = 1 + moment(clicked_date, "YYYY/MM/DD").month();
    let year_value = moment(clicked_date, "YYYY/MM/DD").year();

    let datePayload = {
      full_date: clicked_date,
      month: month_value,
      year: year_value,
    };

    setModalDate(datePayload);
    getRatesForModal(datePayload);
  }, [full_date, selectedModalOccupancy, selectedMealPlans]);

  // Get Rate By Date Of Compititor From All Otas
  const getRatesForModal = async (datePayload: any) => {
    setLoading(true);
    try {
      let rateModalResponse = await rateShopperEndPoint.post(
        `/getratesbydateandplan`,
        {
          month: datePayload.month.toString(),
          year: datePayload.year.toString(),
          hotel_id: hotel_id,
          admin_id: admin_id,
          rate_date: datePayload.full_date,
          meal_plan: selectedMealPlans,
          occupancy: selectedModalOccupancy,
        }
      );

      if (rateModalResponse.data !== null) {
        setCompetitorData(rateModalResponse?.data?.competitor_data);
        setWebsiteSources(rateModalResponse?.data?.website_sources);
        setLoading(false);
      } else {
        setCompetitorData([]);
        setWebsiteSources([]);
        setLoading(false);
      }
    } catch {
      toast.error(CATCH_MESSAGE);
      setLoading(false);
    }
  };
  // To Change Date Handler
  const getPreviousdate = () => {
    const predate = moment(modalDate.full_date)
      .subtract(1, "days")
      .format("YYYY-MM-DD");
    let month_value = 1 + moment(predate, "YYYY/MM/DD").month();
    let year_value = moment(predate, "YYYY/MM/DD").year();
    setModalDate({ full_date: predate, month: month_value, year: year_value });
    getRatesForModal({
      full_date: predate,
      month: month_value,
      year: year_value,
    });
  };
  const getNextdate = () => {
    const postdate = moment(modalDate.full_date)
      .add(1, "days")
      .format("YYYY-MM-DD");
    let month_value = 1 + moment(postdate, "YYYY/MM/DD").month();
    let year_value = moment(postdate, "YYYY/MM/DD").year();
    setModalDate({ full_date: postdate, month: month_value, year: year_value });
    getRatesForModal({
      full_date: postdate,
      month: month_value,
      year: year_value,
    });
  };

  return (
    <Card className="dashboard_modal__card">
      {loading ? (
        <div className="d-flex justify-content-center">
          <Loader />
        </div>
      ) : (
        <>
          {/* Modal Header */}
          <div className="dashboard_modal-header">
            <div className="dashboard_modal_filter_wrapper">
              <div className="calender-and-sliding-btn">
                <div className="date-and-btn-show">
                  <i className="bi bi-calendar-check icon-style"></i>

                  <div className="calender-date-show">
                    {modalDate.full_date?.split("-")?.reverse()?.join("-")}
                  </div>
                  <div className="clander-next-prev-btn">
                    <div
                      className="clander-next-prev-btn-left-rigth"
                      onClick={getPreviousdate}
                    >
                      <i className="bi bi-chevron-left icon-style"></i>
                    </div>
                    <div
                      className="clander-next-prev-btn-left-rigth"
                      onClick={getNextdate}
                    >
                      <i className="bi bi-chevron-right icon-style"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dashboard-lable-selectfield">
                <InputSelectField
                  label={"Occupancy"}
                  value={selectedModalOccupancy}
                  values={occupancy.map((iteam) => iteam.value)}
                  options={occupancy.map((iteam) => iteam.label)}
                  handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setSelectedModalOccupancy(e.target.value)
                  }
                />
              </div>
              <div className="dashboard-lable-selectfield">
                <InputSelectField
                  label={"Plans"}
                  value={selectedMealPlans}
                  options={planOptn}
                  handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setSelectedMealPlans(e.target.value)
                  }
                />
              </div>
            </div>
            {/*  Close Button  */}

            <div className="dashboard_btn-close" onClick={hide}>
              <i className="bi bi-x-lg"></i>
            </div>
          </div>

          {/* Modal Body */}
          <div className="modal-body">
            {competitorData.length > 0 ? (
              <table className="table table-bordered">
                <thead className="table-head">
                  <tr className="table-row">
                    <th
                      className="th-col competitors"
                      style={{ position: "sticky", left: "0", zIndex: "2" }}
                    >
                      Competitors
                    </th>
                    {websiteSources &&
                      websiteSources?.map((item, i) => {
                        return (
                          <th key={i} className="th-col">
                            <img
                              src={item.logo_url}
                              alt={item.source_name}
                              style={{ height: "30px" }}
                            />
                          </th>
                        );
                      })}
                  </tr>
                </thead>

                <tbody>
                  {competitorData &&
                    competitorData.map((compdata, i) => {
                      return (
                        <tr key={i}>
                          <th scope="row " className="stickyLeft">
                            {compdata.hotelname}
                          </th>
                          {websiteSources &&
                            websiteSources.map((item, id) => {
                              if (compdata[item.source_name].length > 0) {
                                return (
                                  <td key={id}>
                                    {compdata[item.source_name].map(
                                      (room_data: any, idx: any) => {
                                        return (
                                          <a
                                            className={`display_competitor_day_price per-ota-small-cards ${
                                              room_data?.closed?.toUpperCase() ===
                                              "Y"
                                                ? "soldOut"
                                                : ""
                                            }`}
                                            href={room_data.url}
                                            key={idx}
                                            target="_blank"
                                          >
                                            {room_data.room_type && (
                                              <b>{room_data?.room_info}</b>
                                            )}

                                            <div className="occupancy-rate-wrapper">
                                              {room_data.occupancy && (
                                                <div className="occupancy-wrapper">
                                                  <i
                                                    className="bi bi-person"
                                                    style={{
                                                      color: "orange",
                                                    }}
                                                  ></i>

                                                  {/* <FontAwesomeIcon icon="coffee" /> */}
                                                  {room_data.occupancy}
                                                </div>
                                              )}
                                              {room_data.is_promo === "Y" && (
                                                <div className="netRate-wrapper">
                                                  <i
                                                    className="bi bi-gift me-2"
                                                    style={{
                                                      color: "red",
                                                    }}
                                                  ></i>
                                                  <b>{room_data.discount}%</b>
                                                </div>
                                              )}

                                              {room_data.room_rate && (
                                                <div className="rate-warraper">
                                                  {room_data.is_promo ===
                                                  "Y" ? (
                                                    <>
                                                      <div>
                                                        <i
                                                          className="bi bi-cash-stack me-2"
                                                          style={{
                                                            color: "#27B92D",
                                                          }}
                                                        ></i>
                                                      </div>
                                                      <div>
                                                        <b>
                                                          ₹{room_data.room_rate}
                                                        </b>
                                                        <br />
                                                        <b className="netRate">
                                                          ₹{room_data.netrate}
                                                        </b>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <div>
                                                      <i
                                                        className="bi bi-cash-stack me-2"
                                                        style={{
                                                          color: "#27B92D",
                                                        }}
                                                      ></i>
                                                      <b>
                                                        ₹{room_data.room_rate}
                                                      </b>
                                                    </div>
                                                  )}
                                                </div>
                                              )}
                                            </div>
                                          </a>
                                        );
                                      }
                                    )}
                                  </td>
                                );
                              } else {
                                return <td key={id}></td>;
                              }
                            })}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            ) : (
              <div>No Data Found 😥</div>
            )}
          </div>
        </>
      )}
    </Card>
  );
};

export default CompititorModal;

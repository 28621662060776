import { AnimatePresence } from "framer-motion";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import rateShopperEndPoint from "../../Apis/rateShopperEndPoint";
import CustomBreadcrumbs from "../../Components/breadcrumbs/CustomBreadcrumbs";
import Card from "../../Components/card/Card";
import Modal from "../../Components/customModal/Modal";
import useModal from "../../Components/customModal/useModal";
import InputSelectField from "../../Components/inputtextfield/InputSelectField";
import LoaderOverlay from "../../Components/loader/LoaderOverlay";
import { DashboardModalDate } from "../../Redux/Actions/DashboardModalAction";
import { RootState } from "../../Redux/store";
import { UpdateSidebar } from "../../UtilityFunctions";
import CompititorModal from "./CompititorModal";

const days_name = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
const occupancy = [
  {
    label: "Any Guest",
    value: "ALL",
  },
  {
    label: "Single Occupancy",
    value: "1",
  },
  {
    label: "Double Occupancy",
    value: "2",
  },
];

const Dashboard = () => {
  UpdateSidebar("Dashboard")
  const { admin_id, auth_token, hotel_id } = useSelector(
    (state: RootState) => state.auth
  );

  const { isShowing, toggle } = useModal();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [isComparing, setIsComparing] = useState(false);
  const [today, setToday] = useState(moment());
  const [resources, setResources] = useState<any[]>([]);
  const [resourcesOptn, setResourcesOptn] = useState<any[]>([]);
  const [planOptn, setPlanOptn] = useState<any[]>([]);
  const [resourcesIdOptn, setResourcesIdOptn] = useState<any[]>([]);
  const [selectedOccupancy, setSelectedOccupancy] = useState<any>("2");
  const [selectedSources, setSelectedSources] = useState<any>("ALL");
  const [selectedPlans, setSelectedPlans] = useState<any>("CP");
  const [fetchDate, setFetchDate] = useState<any>("");
  const [rateData, setRatehData] = useState<any>("");
  const [selectedMonthDays, setSelectedMonthDays] = useState<any[]>([]);
  const [monthNameWeekDays, setMonthNameWeekDays] = useState<any[]>([]);
  const [displayCompetitorHotels, setDisplayCompetitorHotels] = useState<any[]>(
    []
  );
  const [year, setYear] = useState<any>();
  const [month, setMonth] = useState<any>();
  const [competitorHotelNames, setCompetitorHotelNames] = useState<any[]>([]);
  const [competitorHotelDetails, setCompetitorHotelDetails] = useState<any>({});
  const [getcomp_rates, setGetcomp_rates] = useState<any>({});
  const [counterrates1, setCounterrates1] = useState<boolean>(false);
  const [percent, setPercent] = useState<any[]>([]);

  // Logic to build custom calendar
  useEffect(() => {


    let month = moment(today).format("MM");
    let year = moment(today).format("YYYY");
    let monthIndex = Number(month) - 1;
    let date = new Date(Number(year), monthIndex, 1);

    let days_array: any[] = [[]];
    let index = 0;
    let month_name_days: any = [
      {
        month_name: "",
        week_start_date: "",
        week_end_date: "",
        year: null,
        week_start_full_date: "",
        week_end_full_date: "",
      },
    ];
    while (date.getMonth() == monthIndex) {
      days_array[index].push({
        day_name:
          date.getDay() !== 0 ? days_name[date.getDay() - 1] : days_name[6],
        date: date.getDate(),
        day_index: date.getDay(),

        full_date: `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(
          -2
        )}-${("0" + date.getDate()).slice(-2)}`,
      });
      date.setDate(date.getDate() + 1);

      if (date.getMonth() == monthIndex && date.getDay() === 1) {
        month_name_days[index].month_name = moment(month, "MM").format("MMMM");

        month_name_days[index].week_start_date = days_array[index][0].date;
        month_name_days[index].week_end_date =
          days_array[index][days_array[index].length - 1].date;
        month_name_days[index].year = moment(today).format("yyyy");

        month_name_days[index].week_start_full_date =
          days_array[index][0].full_date;

        month_name_days[index].week_end_full_date =
          days_array[index][days_array[index].length - 1].full_date;

        index++;

        days_array.push([]);

        month_name_days.push({
          month_name: "",
          week_start_date: "",
          week_end_date: "",
          year: null,
          week_start_full_date: "",
          week_end_full_date: "",
        });
      }
    }

    month_name_days[month_name_days.length - 1].month_name = moment(
      month,
      "MM"
    ).format("MMMM");

    month_name_days[month_name_days.length - 1].week_start_date =
      days_array[days_array.length - 1][0]?.date;
    month_name_days[month_name_days.length - 1].week_end_date =
      days_array[days_array.length - 1][
        days_array[days_array.length - 1].length - 1
      ]?.date;
    let first_week_length = days_array[0].length;

    if (first_week_length < 7) {
      for (let i = 0; i < 7 - first_week_length; i++) {
        days_array[0].unshift({
          day_name: "",
          date: "",
          day_index: "",
        });
      }
    }

    let display_comp_hotel: any[] = [];
    for (let i = 0; i < month_name_days.length; i++) {
      // display_comp_hotel.push(comp_hotels);
      display_comp_hotel.push(false);
    }
    setDisplayCompetitorHotels(display_comp_hotel);
    setSelectedMonthDays(days_array);
    setMonthNameWeekDays(month_name_days);
    let mon = moment().month(month_name_days[0].month_name).format("M");
    setYear(month_name_days[0].year);
    setMonth(mon);
  }, [today, selectedPlans, selectedOccupancy, selectedSources]);

  useEffect(() => {
    getRates();
  }, [selectedOccupancy, selectedSources, today, selectedPlans]);
  useEffect(() => {
    getResources();
    getMealPlans();
  }, []);
  useEffect(() => {
    const maxPrices = [];
    const minPrices = [];
    const percentages: any[] = [];

    for (const key in rateData) {
      maxPrices.push(rateData[`${key}`].max_price);
      minPrices.push(rateData[`${key}`].min_price);
      percentages.push(rateData[`${key}`].supply);
    }

    // setMaxPrices(maxPrices);
    // setMinPrices(minPrices);
    setPercent(percentages);
  }, [rateData]);

  // Api To Get Meal Plans
  const getMealPlans = async () => {
    try {
      let planRes = await rateShopperEndPoint.get(`/getmealplans`);

      if (planRes.status === 200) {
        setPlanOptn(planRes.data.plans);
      } else {
        setPlanOptn([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Api Call To Get All Resources
  const getResources = async () => {
    try {
      const resourcesResponse = await rateShopperEndPoint.get(
        `/getsources/${hotel_id}`
      );

      if (resourcesResponse?.data?.status === 1) {
        setResources(resourcesResponse?.data?.source_list);
        const sourceOptnArray = ["All Souces"];
        resourcesResponse?.data?.source_list?.forEach((iteam: any) =>
          sourceOptnArray.push(iteam.websitename)
        );
        setResourcesOptn(sourceOptnArray);
        const sourceIdOptnArray = ["ALL"];
        resourcesResponse?.data?.source_list?.forEach((iteam: any) =>
          sourceIdOptnArray.push(iteam.websitecode)
        );
        setResourcesIdOptn(sourceIdOptnArray);
      } else {
        setResources([]);
      }
    } catch { }
  };

  // Api To Get Rates for Table View

  const getRates = async () => {
    setLoading(true);
    const data = {
      month: moment(today).format("M"),
      year: moment(today).year(),
      occupancy: selectedOccupancy,
      source: selectedSources,
      hotel_id: hotel_id,
      admin_id: admin_id,
      meal_plan: selectedPlans,
    };
    try {
      const rateResponse = await rateShopperEndPoint.post(
        `/getratesbyplan`,
        data
      );

      if (rateResponse.data.status === 1) {
        setFetchDate(rateResponse?.data?.fetch_date);
        setRatehData(rateResponse?.data?.cal_output);
        setLoading(false);
      } else {
        setFetchDate("");
        setRatehData("");
        setLoading(false);
      }
    } catch {
      setLoading(false);
    }
  };

  // Compititor Data Retrive function

  const displayCompetitorDetails = async (
    index: number,
    startdate: string,
    enddate: string,
    Year: any
  ) => {
    let startdate_WithLeadingZeros = ("0" + startdate).slice(-2);
    let enddate_WithLeadingZeros = ("0" + enddate).slice(-2);
    let month_WithLeadingZeros = ("0" + month).slice(-2);
    let start_full_date =
      year.toString() +
      "-" +
      month_WithLeadingZeros.toString() +
      "-" +
      startdate_WithLeadingZeros.toString();
    let end_full_date =
      year.toString() +
      "-" +
      month_WithLeadingZeros.toString() +
      "-" +
      enddate_WithLeadingZeros.toString();

    setCompetitorHotelNames([]);
    setCompetitorHotelDetails({});

    try {
      let competitorResponse = await rateShopperEndPoint.post(
        `/getcompetitorwiseratesbyplan`,
        {
          month: month,
          year: year,
          occupancy: selectedOccupancy,
          source: selectedSources,
          hotel_id: hotel_id,
          admin_id: admin_id,
          from_date: start_full_date,
          to_date: end_full_date,
          meal_plan: selectedPlans,
        }
      );

      if (competitorResponse.data !== null) {
        setGetcomp_rates(competitorResponse?.data?.competitor_data);
        setCompetitorHotelNames(competitorResponse?.data?.competitor_hotels);
        setCompetitorHotelDetails(competitorResponse?.data?.competitor_data);
        setCounterrates1(!counterrates1);

        if (displayCompetitorHotels[index] === true) {
          displayCompetitorHotels[index] = false;
        } else {
          displayCompetitorHotels[index] = true;
        }

        for (let i = 0; i < displayCompetitorHotels.length; i++) {
          if (i !== index) {
            displayCompetitorHotels[i] = false;
          }
        }

        setDisplayCompetitorHotels([...displayCompetitorHotels]);
      }
    } catch { }
  };

  // Change Month Handlers
  const switchToPreviousMonth = () => {
    if (moment(today).isAfter(moment())) {
      setToday(moment(today.subtract(1, "M")));
    }
  };

  const switchToNextMonth = () => {
    setToday(moment(today.add(1, "M")));
  };

  // const startWeek = moment().startOf("month").startOf("week");
  // const endWeek = moment().endOf("month").endOf("week");

  // var now = moment().startOf("month");
  // var monday = now.clone().weekday(1);
  // var sunday = now.clone().weekday(7);
  // var isNowWeekday = now.isBetween(monday, sunday, null, "[]");

  // Display Compititor Data On Modal
  const displayRates_onmodal = (date: any) => {
    if (date) {
      let date_WithLeadingZeros = ("0" + date).slice(-2);
      let month_WithLeadingZeros = ("0" + month).slice(-2);
      let fulldate =
        year.toString() +
        "-" +
        month_WithLeadingZeros.toString() +
        "-" +
        date_WithLeadingZeros.toString();
      dispatch(DashboardModalDate(fulldate));
      toggle();
    }
  };

  return (
    <>
      <div className="dashboard_breadcrumbs">
        <CustomBreadcrumbs content={[{ label: "Dashboard" }]} />
        <div className="dashboard-lable-fetch-date">
          Fetch Date : {fetchDate}
        </div>
      </div>

      <hr />
      <div className="dashboard-lable-tabs">
        <div className="dashboard_filter_wrapper">
          <div className="dashboard-lable-selectfield">
            <InputSelectField
              label={"Occupancy"}
              value={selectedOccupancy}
              values={occupancy.map((iteam) => iteam.value)}
              options={occupancy.map((iteam) => iteam.label)}
              handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSelectedOccupancy(e.target.value)
              }
            />
          </div>
          <div className="dashboard-lable-selectfield">
            <InputSelectField
              label={"Plans"}
              value={selectedPlans}
              options={planOptn}
              handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSelectedPlans(e.target.value)
              }
            />
          </div>
          <div className="dashboard-lable-selectfield">
            <InputSelectField
              label={"All Sources"}
              value={selectedSources}
              options={resourcesOptn}
              values={resourcesIdOptn}
              handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSelectedSources(e.target.value)
              }
            />
          </div>
        </div>

        <div className="dashboard__month_change__btn">
          {moment(today).isBefore(moment()) ? (
            <div></div>
          ) : (
            <div
              className="dashboard__month_change__btn__icon"
              onClick={() => {
                switchToPreviousMonth();
              }}
            >
              <i className="bi bi-arrow-left"></i>
              {/* <i className="bi bi-arrow-left-circle-fill"></i> */}
            </div>
          )}

          <div className="dashboard__month_change__btn_month-name">
            {`${moment(today).format("MMM")}-${moment(today).year()}`}
          </div>
          <div
            className="dashboard__month_change__btn__icon"
            onClick={() => {
              switchToNextMonth();
            }}
          >
            <i className="bi bi-arrow-right"></i>
            {/* <i className="bi bi-arrow-right-circle-fill"></i> */}
          </div>
        </div>
      </div>

      {loading ? (
        <LoaderOverlay />
      ) : (
        <Card className="dashboard-card-wrapper">
          {selectedMonthDays?.length > 0 ? (
            <>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="thead-column"
                      id="blank__thead__col"
                    ></th>
                    {days_name.map((weekDay, idx) => {
                      return (
                        <th scope="col" key={idx} className="thead-column">
                          {weekDay}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {selectedMonthDays?.map((iteam, idx) => {
                    return (
                      <>
                        <tr key={idx}>
                          <td className="weekday-interval">
                            {monthNameWeekDays &&
                              `${monthNameWeekDays[idx].month_name.substring(
                                0,
                                3
                              )} ${monthNameWeekDays[idx].week_start_date
                              } - ${monthNameWeekDays[idx].month_name.substring(
                                0,
                                3
                              )} ${monthNameWeekDays[idx].week_end_date}`}
                            <div
                              className="compare-rate-btn"
                              onClick={() =>
                                displayCompetitorDetails(
                                  idx,
                                  monthNameWeekDays[idx].week_start_date,
                                  monthNameWeekDays[idx].week_end_date,
                                  monthNameWeekDays[idx].year
                                )
                              }
                            >
                              Compare Rate
                              {displayCompetitorHotels[idx] === true ? (
                                <i className="bi bi-caret-down-fill"></i>
                              ) : (
                                <i className="bi bi-caret-right-fill"></i>
                              )}
                            </div>
                          </td>
                          {iteam?.map((data: any, index: number) => {
                            return (
                              <td
                                key={index}
                                className="dashboard__table__td"
                                onClick={() => {
                                  displayRates_onmodal(data.date);
                                }}
                              >
                                <div className="supplyPercent-date-wrapper">
                                  {/* ========>>>>Supply Percentage <<<<======= */}
                                  {data.date &&
                                    rateData &&
                                    rateData[data.full_date] &&
                                    rateData[data.full_date] && (
                                      // percent &&
                                      // percent.length > 0 &&
                                      <div
                                        className="supplyPercent"
                                        data-tip=""
                                        data-for={`percentage-${data.date - 1}`}
                                      >
                                        {rateData &&
                                          rateData[data.full_date] &&
                                          rateData[data.full_date].supply}
                                        %
                                        <ReactTooltip
                                          id={`percentage-${data.date - 1}`}
                                        >
                                          Supply Percentage {""}
                                          {rateData &&
                                            rateData[data.full_date] &&
                                            rateData[data.full_date].supply}
                                        </ReactTooltip>
                                      </div>
                                    )}

                                  {/* ========>>>>Calender with Date <<<<======= */}
                                  {data?.date &&
                                    rateData &&
                                    rateData[data?.full_date] &&
                                    rateData[data?.full_date] && (
                                      <div
                                        className={
                                          data?.date && "calendar-date-wrapper"
                                        }
                                      >
                                        {data?.date ? (
                                          <i className="bi bi-calendar-event"></i>
                                        ) : null}
                                        <div className="calendar_date">
                                          {data?.date}
                                        </div>
                                      </div>
                                    )}
                                </div>
                                {/* ========>>>>Min Price <<<<======= */}

                                {data.date &&
                                  rateData &&
                                  rateData[data.full_date] &&
                                  rateData[data.full_date] && (
                                    <div className={data.date && "minPrice"}>
                                      {rateData &&
                                        rateData[data.full_date] &&
                                        rateData[data.full_date].min_price}
                                      {rateData &&
                                        rateData[data.full_date] &&
                                        rateData[data.full_date]
                                          .min_rate_difference > 0 ? (
                                        <span
                                          data-tip=""
                                          data-for={`minprice-${data.date - 1}`}
                                        >
                                          <span className="ms-2 rate_up_icon">
                                            <i className="bi bi-caret-up-fill up_arrow"></i>
                                          </span>
                                          <ReactTooltip
                                            id={`minprice-${data.date - 1}`}
                                          >
                                            Your price is greater than the
                                            minimum price by :
                                            {
                                              rateData[data.full_date]
                                                .min_rate_difference
                                            }
                                          </ReactTooltip>
                                        </span>
                                      ) : null}
                                    </div>
                                  )}
                                {/* ========>>>>Max Price <<<<======= */}

                                {data.date &&
                                  rateData &&
                                  rateData[data.full_date] &&
                                  rateData[data.full_date] && (
                                    <div className={data.date && "maxPrice"}>
                                      {" "}
                                      {rateData &&
                                        rateData[data.full_date] &&
                                        rateData[data.full_date].max_price}
                                      {/* Down Arrow */}
                                      {rateData &&
                                        rateData[data.full_date] &&
                                        rateData[data.full_date]
                                          .max_rate_difference > 0 ? (
                                        <span
                                          data-tip=""
                                          data-for={`maxprice-${data.date + 1}`}
                                        >
                                          <span className="ms-2 rate_down_icon">
                                            <i className="bi bi-caret-down-fill down_arrow"></i>
                                          </span>
                                          <ReactTooltip
                                            id={`maxprice-${data.date + 1}`}
                                          >
                                            Your price is less than the maximum
                                            price by :
                                            {
                                              rateData[data.full_date]
                                                .max_rate_difference
                                            }
                                          </ReactTooltip>
                                        </span>
                                      ) : null}
                                    </div>
                                  )}
                              </td>
                            );
                          })}
                        </tr>
                        {/* =====>>>> Data Of Compititors  <<<<======= */}
                        {displayCompetitorHotels &&
                          displayCompetitorHotels[idx] === true && (
                            <>
                              {competitorHotelNames &&
                                competitorHotelNames.map((hotel_data, id) => {
                                  let display_hotel_code = hotel_data.hotelcode;
                                  return (
                                    <tr key={id}>
                                      <td className="weekday-interval">
                                        {hotel_data.hotelname}
                                      </td>

                                      {iteam.map((items: any, indx: any) => {
                                        let display_date_index =
                                          items.full_date;
                                        return (
                                          <td
                                            key={indx}
                                            className="compititors-data-display"
                                          >
                                            {competitorHotelDetails &&
                                              competitorHotelDetails[
                                              display_hotel_code
                                              ] &&
                                              competitorHotelDetails[
                                                display_hotel_code
                                              ].week_rates &&
                                              display_date_index && (
                                                <>
                                                  {competitorHotelDetails[
                                                    display_hotel_code
                                                  ].week_rates[
                                                    display_date_index
                                                  ] &&
                                                    competitorHotelDetails[
                                                      display_hotel_code
                                                    ].week_rates[
                                                    display_date_index
                                                    ] ? (
                                                    <div className="minPrice">
                                                      {
                                                        competitorHotelDetails[
                                                          display_hotel_code
                                                        ].week_rates[
                                                          display_date_index
                                                        ].min_price
                                                      }
                                                    </div>
                                                  ) : (
                                                    <></>
                                                  )}
                                                  {competitorHotelDetails[
                                                    display_hotel_code
                                                  ].week_rates[
                                                    display_date_index
                                                  ] &&
                                                    competitorHotelDetails[
                                                      display_hotel_code
                                                    ].week_rates[
                                                    display_date_index
                                                    ] ? (
                                                    <div className="maxPrice">
                                                      {
                                                        competitorHotelDetails[
                                                          display_hotel_code
                                                        ].week_rates[
                                                          display_date_index
                                                        ].max_price
                                                      }
                                                    </div>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </>
                                              )}
                                          </td>
                                        );
                                      })}
                                    </tr>
                                  );
                                })}
                            </>
                          )}
                      </>
                    );
                  })}
                </tbody>
              </table>
              <div className="dashboard_legend_wrapper">
                <div className="dashboard-lable-min-price-color-symbol">
                  <i className="bi bi-circle-fill me-2"></i>
                  Minimum-Price
                </div>
                <div className="dashboard-lable-max-price-color-symbol">
                  <i className="bi bi-circle-fill me-2"></i>
                  Maximum-Price
                </div>
              </div>
            </>
          ) : (
            <div>No Data Found 😥</div>
          )}
        </Card>
      )}
      {/* Modal For Compititor Table */}
      <AnimatePresence
        key={"alert"}
        initial={false}
        exitBeforeEnter={true}
        onExitComplete={() => null}
      >
        <Modal isShowing={isShowing} hide={toggle}>
          <CompititorModal
            hide={toggle}
            selectedPlans={selectedPlans}
            selectedOccupancy={selectedOccupancy}
            planOptn={planOptn}
            occupancy={occupancy}
          />
        </Modal>
      </AnimatePresence>
    </>
  );
};

export default Dashboard;

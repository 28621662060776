import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import LoaderOverlay from "../../Components/loader/LoaderOverlay";
import { RootState } from "../../Redux/store";
import rateShopperEndPoint from "../../Apis/rateShopperEndPoint";
import Card from "../../Components/card/Card";

const TrendCalenderView = () => {
  const { admin_id, auth_token, hotel_id } = useSelector(
    (state: RootState) => state.auth
  );

  const [loader, setLoader] = useState(false);
  const [today, setToday] = useState(moment());
  const [year, setYear] = useState();
  const [month, setMonth] = useState();
  const [monthName, setMonthName] = useState();
  const [tableHeaderData, setTableHeaderData] = useState([]);
  const [tableTrendData, setTableTrendData] = useState([]);

  useEffect(() => {
    getTrendData();
  }, [today]);

  // Get Trend Data From Api
  const getTrendData = async () => {
    setLoader(true);
    try {
      let trendResponse = await rateShopperEndPoint.post(`${"/gettrend"}`, {
        month: moment(today).format("M"),
        year: moment(today).year(),
        hotel_id: hotel_id,
        admin_id: admin_id,
      });

      if (trendResponse.data.status === 1) {
        setTableHeaderData(trendResponse.data.collection_dates);
        setTableTrendData(trendResponse.data.trend);
        setLoader(false);
      } else {
        setTableHeaderData([]);
        setTableTrendData([]);
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
    }
  };

  const switchToPreviousMonth = () => {
    if (moment(today).isAfter(moment())) {
      setToday(moment(today.subtract(1, "M")));
    }
  };

  const switchToNextMonth = () => {
    setToday(moment(today.add(1, "M")));
  };

  return (
    <Card className="trend-wrapper">
      {/* Month Change Button */}
      <div className="month-change-btn-wrapper">
        {moment(today).isBefore(moment()) ? (
          <div></div>
        ) : (
          <div
            className={`next-prev-arrow-wrapper`}
            onClick={switchToPreviousMonth}
          >
            <i className="bi bi-arrow-left arrow-color"></i>
          </div>
        )}

        <div className="month-change-month-name">
          {`${moment(today).format("MMM")}-${moment(today).year()}`}
        </div>
        <div className="next-prev-arrow-wrapper" onClick={switchToNextMonth}>
          <i className="bi bi-arrow-right arrow-color"></i>
        </div>
      </div>
      {/* Table */}
      {loader ? (
        <LoaderOverlay />
      ) : (
        <div className="trend-rates-wrapper">
          <table className="trend-table">
            <thead className="trend-table-head">
              <tr className="trend-table-head-row">
                <th className="trend-table-heading">
                  <div className="table-label">Shop Dates ➡</div>

                  <div className="table-label">Rate Dates ⬇</div>
                </th>
                {tableHeaderData?.length > 0 &&
                  tableHeaderData?.map((dates, idx) => {
                    return (
                      <th className="trend-table-heading " key={idx}>
                        {dates}
                      </th>
                    );
                  })}
                <th className="trend-table-heading trend-col">Trend</th>
              </tr>
            </thead>
            <tbody className="trend-table-head">
              {tableTrendData?.length > 0 &&
                tableTrendData?.map((iteam: any, index: any) => {
                  return (
                    <tr
                      className={`trend-table-head-row ${
                        iteam?.highlight === 1 ? "highlight" : ""
                      } `}
                      key={index}
                    >
                      <td className="trend-table-heading trend-date-row">
                        {iteam?.rate_date}
                        <br />
                        {iteam?.dow}
                      </td>
                      {iteam?.rates?.map((rates: any, idx: any) => {
                        return (
                          <td
                            className="trend-table-heading trend-body-data"
                            key={idx}
                          >
                            <div>
                              {rates?.avg_onsiterate === 0 ? (
                                "NA"
                              ) : (
                                <div className="trend-rate-bydate">
                                  {rates?.avg_onsiterate}
                                </div>
                              )}

                              <div>
                                {rates?.difference ? (
                                  <div className="trend-rate-differenece">
                                    {rates?.difference}
                                  </div>
                                ) : (
                                  <div></div>
                                )}
                              </div>
                            </div>

                            {rates?.direction === "UP" ? (
                              <div>
                                <i className="bi bi-arrow-up trend-arrow-up"></i>
                              </div>
                            ) : rates?.direction === "DOWN" ? (
                              <div>
                                <i className="bi bi-arrow-down trend-arrow-down"></i>
                              </div>
                            ) : rates?.direction === "SAME" &&
                              rates?.avg_onsiterate !== 0 ? (
                              <div className="trend-arrow-same">
                                ---
                                {/* <i className="bi bi-grip-horizontal "></i> */}
                              </div>
                            ) : (
                              <></>
                            )}
                          </td>
                        );
                      })}

                      {iteam?.trending?.toUpperCase() === "POSITIVE" ? (
                        <td className="trend-table-heading ">
                          <i className="bi bi-graph-up-arrow tren-up"></i>
                        </td>
                      ) : iteam?.trending?.toUpperCase() === "NEGATIVE" ? (
                        <td className="trend-table-heading ">
                          <i className="bi bi-graph-down-arrow tren-down"></i>
                        </td>
                      ) : (
                        <td className="trend-table-heading ">
                          <i className="bi bi-grip-horizontal tren-equal"></i>
                        </td>
                      )}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
    </Card>
  );
};

export default TrendCalenderView;

import React, { useEffect } from "react";
import {
  DirectLogInAction,
  LoginSuccess,
} from "../../Redux/Actions/LoginAction";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const Loginwithoutcredential = () => {
  let dispatch = useDispatch();
  const navigate = useNavigate();
  let login_data: any = useParams();

  useEffect(() => {
    let send_login_data = {
      admin_id: atob(login_data.admin_id),
      auth_token: login_data.auth_token,
      company_id: atob(login_data.company_id),
      full_name: atob(login_data.full_name),
      hotel_id: atob(login_data.hotel_id),
      hotel_name: atob(login_data.hotel_name),
    };
    dispatch(LoginSuccess(send_login_data));
    dispatch(DirectLogInAction(true));
    navigate("/");
  }, [login_data]);
  return <></>;
};
export default Loginwithoutcredential;

import React from "react";
import bjLogo from "../../Assets/images/bj_logo_blue_1200.png";

const LoaderOverlay = () => {
  return (
    <div className="overlay">
      <div className="overlay__inner">
        <div className="overlay__content">
          <div className='d-flex justify-content-center align-items-center mt-5'>
            <div className="loader"><img src={bjLogo} /></div>
          </div>
          {/* <span className="spinner"></span> */}
        </div>
      </div>
    </div>
  );
};

export default LoaderOverlay;

import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import DashboardModalReducer from "./Reducers/DashboardModalReducer";
import LoginReducer from "./Reducers/LoginReducer";
import PromptReducer from "./Reducers/PromptReducer";
import SidebarReducer from "./Reducers/SidebarReducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "sidebar", "prompt", "dashboardModal"],
};

const rootReducer = combineReducers({
  auth: LoginReducer,
  sidebar: SidebarReducer,
  prompt: PromptReducer,
  dashboardModal: DashboardModalReducer,
});

export default persistReducer(persistConfig, rootReducer);

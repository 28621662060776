import React from "react";
import Login from "../../Pages/Login/Login";
import Loginwithoutcredential from "../../Pages/Login/Loginwithoutcredential";
import { Routes, Route, Navigate } from "react-router-dom";
// import ResetPassword from "../pages/ResetPassword";
// import EnterNewPassword from "../pages/EnterNewPassword";
// import SignUp from "../pages/SignUp/SignUp";
// import SignUpForm from "../pages/SignUp/SignUpForm";

const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route
        path="/:company_id/:comp_hash/:hotel_id/:admin_id/:auth_token/:full_name/:hotel_name"
        element={<Loginwithoutcredential />}
      />
      {/* <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/signup-form" element={<SignUpForm />} />
      <Route path="/new-password" element={<EnterNewPassword />} /> */}
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
};

export default AuthRoutes;

import React, { useEffect, useState } from "react";
import { ComputedCell, ResponsiveHeatMap } from "@nivo/heatmap";
import CustomToolTip from "./CustomToolTip";
import rateShopperEndPoint from "../../Apis/rateShopperEndPoint";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import { toast } from "react-toastify";
import { CATCH_MESSAGE } from "../../UtilityFunctions";
import LoaderOverlay from "../../Components/loader/LoaderOverlay";
import CustomBreadcrumbs from "../../Components/breadcrumbs/CustomBreadcrumbs";

interface SizeVariationConfig {
  // use auto min/max values if unspecified
  values?: [min: number, max: number];
  // expressed as a range from 0~1
  sizes: [min: number, max: number];
}

const Availibilty = () => {
  const { admin_id, auth_token, hotel_id } = useSelector(
    (state: RootState) => state.auth
  );
  const [loader, setLoader] = useState<boolean>(false);
  const [availableData, setAvailableData] = useState<any[]>([]);

  useEffect(() => {
    getAvailibility();
  }, []);
  // Get Availibilty
  const getAvailibility = async () => {
    setLoader(true);
    try {
      let availibilityResponse = await rateShopperEndPoint.post(
        `/getavailabilitygraph`,
        {
          hotel_id: hotel_id,
          admin_id: admin_id,
        }
      );

      if (availibilityResponse.data.status === 1) {
        setAvailableData(availibilityResponse.data.graphdata);
        setLoader(false);
      } else {
        setAvailableData([]);
        setLoader(false);
      }
    } catch {
      setLoader(false);
      toast.error(CATCH_MESSAGE);
    }
  };

  return (
    <>
      <CustomBreadcrumbs content={[{ label: "Availibilty" }]} />
      <hr />
      {loader ? (
        <LoaderOverlay />
      ) : (
        <div className="trend__graph__view__wrapper">
          <div className="trend__graph__height">
            <ResponsiveHeatMap
              data={availableData}
              margin={{ top: 10, right: 0, bottom: 60, left: 150 }}
              valueFormat=">-.2s"
              //   enableGridX={true}
              forceSquare={true}
              sizeVariation={{
                // values: [0, 1],
                sizes: [1, 1],
              }}
              enableGridY={true}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -60,
                // legend: "Dates",
                legendPosition: "end",
                legendOffset: -72,
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                // legend: "Hotel",
                legendPosition: "middle",
                legendOffset: -72,
              }}
              tooltip={CustomToolTip}
              colors={{
                type: "diverging",
                scheme: "reds",
                minValue: 1,
                maxValue: 0,
                divergeAt: 1,
              }}
              emptyColor="#555555"
              borderWidth={1}
              borderRadius={2}
              borderColor="rgba(0,0,0,0.1)"
              enableLabels={false}
              cellComponent="rect"
              legends={[]}
              hoverTarget="cell"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Availibilty;

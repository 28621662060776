import React, { useEffect, useState } from "react";

import ReactTooltip from "react-tooltip";

import { useNavigate } from "react-router";

import TrendGraphView from "./TrendGraphView";
import TrendCalenderView from "./TrendCalenderView";
import CustomBreadcrumbs from "../../Components/breadcrumbs/CustomBreadcrumbs";
import { UpdateSidebar } from "../../UtilityFunctions";

const Trend = () => {
  const [activeMenuTab, setActiveMenuTab] = useState("graph");
  // const view = ["graph", "calender"];
  const navigate = useNavigate();
  UpdateSidebar("Trend")

  const switchMenuTab = (tab: any) => {
    setActiveMenuTab(tab);
  };

  return (
    <>
      <div className="trend-breadcrumb-toggle-wrapper">
        <CustomBreadcrumbs content={[{ label: "Trend" }]} />
        {activeMenuTab === "graph" ? (
          <></>
        ) : (
          <h2 className="trend-table-label">Trend in Last 7 Days</h2>
        )}
        <div className="graph-calender-tab-wrapper">
          <div className="graph-calender-toggle">
            <ul className="nav nav-tabs">
              <li
                className="nav-item"
                role="presentation"
                // key={tab}
                onClick={() => switchMenuTab("graph")}
                data-for="graph"
                data-tip="Graph View"
              >
                <a
                  className={`nav-link ${activeMenuTab === "graph" ? "active" : ""
                    }`}
                  id={`graph-tab`}
                  data-bs-toggle="tab"
                  data-bs-target={`#graph`}
                  aria-controls={`#graph`}
                  role="tab"
                  aria-selected="true"
                >
                  <i className="bi bi-graph-up"></i>
                </a>
              </li>
              <ReactTooltip id="graph" />
              <li
                className="nav-item"
                role="presentation"
                // key={tab}
                onClick={() => switchMenuTab("calender")}
                data-for="calender"
                data-tip="Calender View"
              >
                <a
                  className={`nav-link ${activeMenuTab === "calender" ? "active" : ""
                    }`}
                  id={`calender-tab`}
                  data-bs-toggle="tab"
                  data-bs-target={`#calender`}
                  aria-controls={`#calender`}
                  role="tab"
                  aria-selected="true"
                >
                  <i className="bi bi-calendar-event"></i>
                </a>
              </li>
              <ReactTooltip id="calender" />
            </ul>
          </div>
        </div>
      </div>

      <hr />
      {/* Tab for Calender and Graph View */}
      <div className="tab-component-warpper">
        {activeMenuTab === "graph" ? <TrendGraphView /> : <TrendCalenderView />}
      </div>
    </>
  );
};

export default Trend;

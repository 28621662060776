import React, { useState } from "react";
import { toast } from "react-toastify";
import rateShopperEndPoint from "../../Apis/rateShopperEndPoint";
import Button from "../../Components/buttons/Button";
import InputTextField from "../../Components/inputtextfield/InputTextField";
import { CATCH_MESSAGE } from "../../UtilityFunctions";
export interface IEditStandardRoomSliderProps {
  onClose: () => void;
  getStandardizeRoomDetails: () => void;
  roomData: any;
}

const EditStandardRoom: React.FC<IEditStandardRoomSliderProps> = ({
  onClose,
  getStandardizeRoomDetails,
  roomData,
}) => {
  const [roomName, setRoomName] = useState<string>(
    roomData.standardized_room_type
  );
  const [loading, setLoading] = useState<boolean>(false);

  //   Api To Add Standardized Room
  const editStandardizedRoom = async () => {
    setLoading(true);
    try {
      let addRes = await rateShopperEndPoint.post(
        `updatestandardizedroomtype`,
        {
          id: roomData.id,
          room_type_name: roomName ?? " ",
        }
      );
      if (addRes.data.status === 1) {
        toast.success(addRes.data.message);
        setLoading(false);
        getStandardizeRoomDetails();
        onClose();
      } else {
        toast.error(addRes.data.message);
        setLoading(false);
        getStandardizeRoomDetails();
        onClose();
      }
    } catch (error) {
      toast.error(CATCH_MESSAGE);
      setLoading(false);
    }
  };
  return (
    <>
      <div className="sliding-pane-header">
        <div className="slider-close-btn" onClick={onClose}>
          <i className="bi bi-x-circle"></i>
        </div>
        <div>Edit Standardized Room - {roomData.standardized_room_type}</div>
      </div>
      <div className="mt-4 me-4 ms-4">
        <InputTextField
          label={"Enter Room Name"}
          value={roomName}
          handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setRoomName(e.target.value)
          }
        />
      </div>
      <div className="mt-2 me-4 ms-4" style={{ width: "10px" }}>
        <Button handleClick={() => editStandardizedRoom()} loader={loading}>
          Save
        </Button>
      </div>
    </>
  );
};

export default EditStandardRoom;

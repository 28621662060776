import React from "react";

const CustomToolTip = (props: any) => {
  if (props?.cell?.value === 0) {
    return (
      <div className="custom-tooltip-label">{`${props?.cell?.id}(Sold Out)`}</div>
    );
  } else {
    return (
      <div className="custom-tooltip-label">{`${props?.cell?.id}(Available)`}</div>
    );
  }
};

export default CustomToolTip;

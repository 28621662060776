import React, { useState } from "react";
import { Link } from "react-router-dom";
import Sidebar_Menu from "./SidebarMenu.json";
import SidebarItem from "./SidebarItem";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../Redux/store";
import { ActiveSidebarItem } from "../Redux/Actions/SidebarAction";
import { Logout } from "../UtilityFunctions";
import SlidingPane from "react-sliding-pane";
// import PasswordChangeSlider from "./PasswordChangeSlider";

export interface IMenuItemsProps {
  label: string;
  icon: string;
  dropdown?: string[];
  showDropdown?: boolean;
}

const AppSidebar: React.FC = () => {
  const dispatch = useDispatch();
  const { full_name } = useSelector((state: RootState) => state.auth);
  const { directLogin } = useSelector((state: RootState) => state.auth);

  const [openSlide, setOpenSlide] = useState(false);
  const [channels, setChannels] = useState([
    {
      label: "Website",
      alerts: 0,
    },
  ]);

  const { active_sidebar_item } = useSelector(
    (state: RootState) => state.sidebar
  );

  const handleSidebarItemClick: (item: IMenuItemsProps) => void = (item) => {
    dispatch(ActiveSidebarItem(item.label));
    if (item.label === active_sidebar_item) {
      item.showDropdown = !item.showDropdown;
    }
  };

  return (
    <>
      <div className="sidebar-main-wrapper">
        <div className="sidebar-wrapper sidebar-position">
          <div className="company__logo__wrapper">
            <Link to="/">
              <div className="company--logo"></div>
            </Link>
          </div>
          <ul className="sidebar__list__wrapper">
            {Sidebar_Menu.map((item: any, index: number) => {
              return (
                <SidebarItem
                  key={index}
                  handleClick={() => handleSidebarItemClick(item)}
                  isNew={item.isNew}
                  href={item.href}
                  label={item.label}
                  icon={item.icon}
                  dropdown={item.dropdown}
                  showDropdown={item.showDropdown}
                  active={active_sidebar_item === item.label}
                />
              );
            })}
          </ul>
          <div className="user__profile__div">
            <div
              className="user__profile__info"
              onClick={() => {
                setOpenSlide(true);
              }}
            >
              <i className="bi bi-person-check-fill me-3"></i>
              <div className="user__name">
                {full_name?.length > 13
                  ? full_name.slice(0, 13) + "..."
                  : full_name}
              </div>
            </div>
            {directLogin ? (
              <></>
            ) : (
              <div className="hotel__logout" onClick={Logout}>
                <i className="bi bi-box-arrow-right logout-icon-size"></i>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={openSlide}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => {
          setOpenSlide(false);
        }}>
        <PasswordChangeSlider
          onClose={() => {
            setOpenSlide(false);
          }}></PasswordChangeSlider>
      </SlidingPane> */}
    </>
  );
};

export default AppSidebar;

import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import rateShopperEndPoint from "../../Apis/rateShopperEndPoint";
import Button from "../../Components/buttons/Button";
import Loader from "../../Components/loader/Loader";
import { Capitalize, CATCH_MESSAGE } from "../../UtilityFunctions";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
export interface IAddCompsetSliderProps {
  onClose: () => void;
  getCompsetDetails: () => void;
}

const AddCompTableHead = ["HOTEL NAME", "ADDRESS", " CITY", " STATE"];

const AddCompsetSlider: React.FC<IAddCompsetSliderProps> = ({
  onClose,
  getCompsetDetails,
}) => {
  const { admin_id, auth_token, hotel_id } = useSelector(
    (state: RootState) => state.auth
  );

  const [criteria, setCriteria] = useState<string>("zip");
  const [searchValue, setSearchValue] = useState<string>("");
  const [allhotels, setAllhotels] = useState<any[]>([]);
  const [selectedhotel, setSelectedhotel] = useState<any[]>([]);
  const [selectedhotelcodes, setSelectedhotelcodes] = useState<any[]>([]);

  const [hotel_success_alert, setHotel_success_alert] = useState(false);
  const [addhotels_success_msg, setAddhotels_success_msg] = useState();

  const [selectedhotel_codes, setSelectedhotel_codes] = useState([]);
  const [place, setPlace] = useState("Enter zip");
  const [loader, setLoader] = useState<boolean>(false);

  const [addloader, setAddLoader] = useState<boolean>(false);
  const [pagenumber, setPagenumber] = useState<number>(0);

  const hotelsperpage = 13;
  const pagesvisited = pagenumber * hotelsperpage;
  const pageCount = Math.ceil(allhotels.length / hotelsperpage);
  const changePage = (props: any) => {
    setPagenumber(props.selected);
  };

  const zipcreteria = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCriteria(e.target.value);
    setSearchValue("");
  };
  const citycreteria = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCriteria(e.target.value);
    setSearchValue("");
  };
  const hotelcreteria = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCriteria(e.target.value);
    setSearchValue("");
  };

  // For Searching Hotels
  const searchHotels = async () => {
    setLoader(true);
    try {
      let res = await rateShopperEndPoint.get(
        `/searchhotels/${criteria}/${searchValue}`
      );

      if (res.data.status === 1) {
        setAllhotels(res.data.hotel_list);
        setLoader(false);
      } else {
        setAllhotels([]);
        setLoader(false);
      }
    } catch {
      toast.error(CATCH_MESSAGE);
      setLoader(false);
    }
  };
  const handleCheckboxChange = (e: any, selected_hotel: any) => {
    let newArray = [...selectedhotel];
    let newArray_hotelcodes: any = [];
    if (e.target.checked) {
      newArray.push(selected_hotel);
    } else {
      newArray = newArray.filter((hotel) => {
        return hotel.hotelcode !== selected_hotel.hotelcode;
      });
    }

    newArray.map((hotel) => {
      newArray_hotelcodes.push(hotel.hotelcode);
    });
    // if (newArray.length > 5) {
    //   e.preventDefault();
    //   // alert("You cannot select more than five hotels");

    //   let secondarray = [newArray.pop(e.target.selected_hotel)];
    // }

    setSelectedhotelcodes(newArray_hotelcodes);
    setSelectedhotel(newArray);
  };

  // To Add New Hotel To Compset List

  const addSelectedHotels = async () => {
    setAddLoader(true);
    try {
      let hotelRes = await rateShopperEndPoint.post(`/addcompsethotels`, {
        hotel_id: hotel_id,
        compset_hotel_codes: selectedhotelcodes.toString(),
        admin_id: admin_id,
      });

      if (hotelRes.data.status === 1) {
        toast.success(hotelRes.data.message);
        getCompsetDetails();
        setAddLoader(false);
        onClose();
      } else {
        toast.error(hotelRes.data.message);
        getCompsetDetails();
        setAddLoader(false);
        onClose();
      }
    } catch {
      setAddLoader(false);
      toast.error(CATCH_MESSAGE);
      onClose();
    }
  };

  return (
    <>
      {/* Header */}
      <div className="sliding-pane-header">
        <div className="slider-close-btn" onClick={onClose}>
          <i className="bi bi-x-circle"></i>
        </div>
        <div>Add Compititor Hotel</div>
      </div>
      {/* Body */}
      <div className="compitior-slider-body">
        <div className="checkbox-input-btn">
          <div className="zip-city-hotel-wrapper">
            <div>
              <input
                type="checkbox"
                className="form-check-input me-2"
                checked={criteria === "zip"}
                id="zip"
                name="z"
                value="zip"
                onChange={(e) => {
                  zipcreteria(e);
                }}
              />
              <label htmlFor="">Zip</label>
            </div>
            <div>
              <input
                type="checkbox"
                className="form-check-input me-2"
                checked={criteria === "city"}
                id="city"
                name="z"
                value="city"
                onChange={(e) => {
                  citycreteria(e);
                }}
              />
              <label htmlFor="">City</label>
            </div>
            <div>
              <input
                type="checkbox"
                className="form-check-input me-2"
                id="hotel"
                checked={criteria === "hotel"}
                name="z"
                value="hotel"
                onChange={(e) => hotelcreteria(e)}
              />
              <label htmlFor="">Hotels</label>
            </div>
          </div>
          <div className="input__text__btn__wrapper">
            <input
              type="text"
              className="form-input-text"
              value={searchValue}
              placeholder={"Enter " + Capitalize(criteria)}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <Button
              icon="bi bi-search"
              handleClick={() => {
                searchHotels();
              }}
            >
              Search
            </Button>
          </div>
          <div className="selected_hotel_add_wrapper">
            <div className="selected_hotel_number">
              {selectedhotel.length} Selected
            </div>
            <div>
              <Button
                handleClick={() => addSelectedHotels()}
                loader={addloader}
              >
                Add
              </Button>
            </div>
          </div>
        </div>
        {loader ? (
          <div
            style={{
              height: "250px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader />
          </div>
        ) : allhotels.length > 0 ? (
          <div className="add_compset_table_wrapper">
            <table className="comp-ota-table table-bordered mt-3">
              <thead className="comp-ota-table-head">
                <tr>
                  <th className="comp-ota-table-header text-center"></th>
                  {AddCompTableHead?.map((iteam: string) => {
                    return (
                      <th className="comp-ota-table-header hotel-ota-name">
                        {iteam}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {allhotels &&
                  allhotels
                    .slice(pagesvisited, pagesvisited + hotelsperpage)
                    ?.map(function (hotel, i) {
                      return (
                        <tr key={i} className="add-comp-table-body-tr">
                          <td className="add-comp-table-body-td ">
                            <input
                              type="checkbox"
                              className="add-comp-input-checkbox"
                              id={hotel.hotelname}
                              value={hotel.hotelname}
                              checked={selectedhotelcodes.includes(
                                hotel.hotelcode
                              )}
                              onChange={(e) => handleCheckboxChange(e, hotel)}
                            />
                          </td>

                          {/* <td className="text-center">{i}</td> */}
                          <td className="add-comp-table-body-td">
                            {hotel.hotelname}
                          </td>
                          <td className="add-comp-table-body-td">
                            {hotel.address}
                          </td>
                          <td className="add-comp-table-body-td">
                            {hotel.city}
                          </td>
                          <td className="add-comp-table-body-td">
                            {hotel.state}
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
            <div className="pagination__wrapper">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousbtn"}
                nextLinkClassName={"nextbtn"}
                disabledClassName={"paginationdisabled"}
                activeClassName={"paginationactive"}
              />
            </div>
          </div>
        ) : searchValue.length > 0 ? (
          <div className="no_data_found">No Data Found 😥</div>
        ) : (
          <div className="no_data_found">
            Please Select and Enter Zip,City,Hotel
          </div>
        )}
      </div>
    </>
  );
};

export default AddCompsetSlider;

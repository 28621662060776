import React, { useEffect } from "react";
import { Routes, Route, Outlet, Navigate, useLocation } from "react-router-dom";

// import SelectProperty from "../pages/SelectProperty";
import DefaultLayout from "../../Layout/DefaultLayout";
import Availibilty from "../../Pages/Availibilty/Availibilty";
import Compset from "../../Pages/Compset/Compset";
import Dashboard from "../../Pages/Dashboard/Dashboard";
import Error from "../../Pages/Error/Error";
import Login from "../../Pages/Login/Login";
import Loginwithoutcredential from "../../Pages/Login/Loginwithoutcredential";
import RoomType from "../../Pages/RoomType/RoomType";
import Sources from "../../Pages/Sources/Sources";
import Trend from "../../Pages/Trend/Trend";
import TrendGraph from "../../Pages/Trend/TrendGraphView";

const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path="/:company_id/:comp_hash/:hotel_id/:admin_id/:auth_token/:full_name/:hotel_name"
        element={<Loginwithoutcredential />}
      />
      <Route path="/" element={<DefaultLayout />}>
        <Route index element={<Dashboard />} />
        <Route path="trend" element={<Outlet />}>
          <Route index element={<Trend />} />
        </Route>
        <Route path="availability" element={<Outlet />}>
          <Route index element={<Availibilty />} />
        </Route>
        <Route path="compset" element={<Outlet />}>
          <Route index element={<Compset />} />
        </Route>
        <Route path="sources" element={<Outlet />}>
          <Route index element={<Sources />} />
        </Route>
        <Route path="room-type" element={<Outlet />}>
          <Route index element={<RoomType />} />
        </Route>
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<Error />} />
    </Routes>
  );
};

export default AppRoutes;

import React, { Dispatch, SetStateAction, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import rateShopperEndPoint from "../../Apis/rateShopperEndPoint";
import Button from "../../Components/buttons/Button";
import InputTextField from "../../Components/inputtextfield/InputTextField";
import { RootState } from "../../Redux/store";
import { CATCH_MESSAGE } from "../../UtilityFunctions";
export interface IAddStandardRoomSliderProps {
  onClose: () => void;
  getStandardizeRoomDetails: () => void;
}

const AddStandardRoom: React.FC<IAddStandardRoomSliderProps> = ({
  onClose,
  getStandardizeRoomDetails,
}) => {
  const { admin_id, auth_token, hotel_id } = useSelector(
    (state: RootState) => state.auth
  );
  const [roomName, setRoomName] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  //   Api To Add Standardized Room
  const addStandardizedRoom = async () => {
    setLoading(true);
    try {
      let addRes = await rateShopperEndPoint.post(`addstandardizedroomtype`, {
        hotel_id: hotel_id,
        room_type_name: roomName ?? "",
      });
      if (addRes.data.status === 1) {
        toast.success(addRes.data.message);
        setLoading(false);
        getStandardizeRoomDetails();
        onClose();
      } else {
        toast.error(addRes.data.message);
        setLoading(false);
        onClose();
      }
    } catch (error) {
      toast.error(CATCH_MESSAGE);
      setLoading(false);
    }
  };
  return (
    <>
      <div className="sliding-pane-header">
        <div className="slider-close-btn" onClick={onClose}>
          <i className="bi bi-x-circle"></i>
        </div>
        <div>Add Standardized Room</div>
      </div>
      <div className="mt-4 me-4 ms-4">
        <InputTextField
          label={"Enter Room Name"}
          value={roomName}
          handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setRoomName(e.target.value)
          }
        />
      </div>
      <div className="mt-2 me-4 ms-4" style={{ width: "10px" }}>
        <Button
          handleClick={() => {
            if (!roomName) {
              toast.error("Please enter a room name");
            } else {
              addStandardizedRoom();
            }
          }}
          loader={loading}
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default AddStandardRoom;

import React, { useState } from "react";
import { Outlet } from "react-router-dom";

import AppHeader from "./AppHeader";
import AppSidebar from "./AppSidebar";
import AppContent from "./AppContent";

// import animate css
import "animate.css";

// custom confirmation prompt
// import ConfirmationPrompt from './ConfirmationPrompt';

// Switch property animation
// import SwitchProperty from './SwitchProperty';

const DefaultLayout: React.FC = () => {
  return (
    <>
      {/* <SwitchProperty /> */}
      {/* <ConfirmationPrompt /> */}
      {/* <SwitchProperty /> */}
      <div>
        <AppHeader />
        <div>
          <AppSidebar />
          <AppContent>
            {/* <Breadcrumps /> */}
            <Outlet />
          </AppContent>
        </div>
      </div>
    </>
  );
};

export default DefaultLayout;

import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

// import bootstrap
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";

// import bootstrap icon
import "bootstrap-icons/font/bootstrap-icons.css";

// import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
// import "font-awesome/css/font-awesome.min.css";

// Import styles of sliding pane
import "react-sliding-pane/dist/react-sliding-pane.css";

//import redux elements
import { useSelector } from "react-redux";
import { RootState } from "./Redux/store";

// Custom Style Sheet
import "./Assets/css/styles.min.css";

// import routes
import AuthRoutes from "./Routes/AuthRoutes/AuthRoutes";
import AppRoutes from "./Routes/AppRoutes/AppRoutes";

import { PersistGate } from "redux-persist/es/integration/react";
import { persistor } from "./Redux/store";

// import React Toastify
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserView, MobileView } from "react-device-detect";
import AppDownloadScreen from "./MobileApp/AppDownloadScreen";

function App() {
  const isAuthorized = useSelector((state: RootState) => state.auth.auth_token);

  return (
    <PersistGate loading={null} persistor={persistor}>
      {/* This section will be visible for desktop*/}
      <BrowserView>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          closeOnClick
          hideProgressBar={true}
        />
        <Router>{isAuthorized ? <AppRoutes /> : <AuthRoutes />}</Router>
      </BrowserView>

      {/* This section will be visible for mobile or tablet */}
      <MobileView>
        <AppDownloadScreen />
      </MobileView>
    </PersistGate>
  );
}

export default App;

import React, { useState } from "react";

// importing mui components
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export interface IInputPasswordFieldProps {
  label?: string;
  value: string;
  onEnterKey?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  helperText?: string;
  outlined?: boolean;
}

const InputPasswordField: React.FC<IInputPasswordFieldProps> = ({
  label,
  value,
  handleChange,
  handleFocus,
  helperText,
  onEnterKey,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel htmlFor={label}>{label ? label : "Password"}</InputLabel>
      <OutlinedInput
        id={label}
        value={value}
        className="input__password_textfield"
        onFocus={handleFocus}
        onChange={handleChange}
        onKeyPress={onEnterKey}
        label={label ? label : "Password"}
        type={showPassword ? "text" : "password"}
        error={helperText?.trim() ? true : false}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              tabIndex={-1}
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
              edge="end"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
      <FormHelperText className="text-danger">
        {helperText ? helperText : " "}
      </FormHelperText>
    </FormControl>
  );
};

export default InputPasswordField;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import rateShopperEndPoint from "../../Apis/rateShopperEndPoint";
import CustomBreadcrumbs from "../../Components/breadcrumbs/CustomBreadcrumbs";
import Button from "../../Components/buttons/Button";
import Card from "../../Components/card/Card";
import Modal from "../../Components/customModal/Modal";
import useModal from "../../Components/customModal/useModal";
import LoaderOverlay from "../../Components/loader/LoaderOverlay";
import { RootState } from "../../Redux/store";
import { CATCH_MESSAGE, UpdateSidebar } from "../../UtilityFunctions";
import SlidingPane from "react-sliding-pane";
import AddCompsetSlider from "./AddCompsetSlider";
import {
  CancelConfirmationPrompt,
  ShowConfirmationPrompt,
} from "../../Redux/Actions/PromptAction";
import SweetAlert from "react-bootstrap-sweetalert";
import Alert from "../../Components/alert/Alert";
import ReactPaginate from "react-paginate";
import { AnimatePresence } from "framer-motion";

const tableHeadData = ["HOTEL NAME", " ADDRESS", " ZIP", " CITY", " STATE"];

const Compset = () => {

  UpdateSidebar("Compset")
  // const { isShowing, toggle } = useModal();
  const { admin_id, auth_token, hotel_id } = useSelector(
    (state: RootState) => state.auth
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [selectall, setSelectall] = useState<boolean>(false);
  const [compsetList, setCompsetList] = useState<any[]>([]);
  const [remove_hotel, setRemove_hotel] = useState<any[]>([]);
  const [addSliderOpen, setAddSliderOpen] = useState<boolean>(false);
  const [hotel_remove_alert, setHotel_remove_alert] = useState(false);

  const [pagenumber, setPagenumber] = useState<number>(0);

  const hotelsperpage = 13;
  const pagesvisited = pagenumber * hotelsperpage;
  const pageCount = Math.ceil(compsetList.length / hotelsperpage);
  const changePage = (props: any) => {
    setPagenumber(props.selected);
  };

  useEffect(() => {
    getCompsetDetails();
  }, []);
  // Get Compset Details
  const getCompsetDetails = async () => {
    setLoading(true);
    try {
      let compsetData = await rateShopperEndPoint.get(
        `/getcompset/${hotel_id}`
      );

      if (compsetData.data.status === 1) {
        setCompsetList(compsetData?.data?.compset_list);
        setLoading(false);
      } else {
        setCompsetList([]);
        setLoading(false);
      }
    } catch (error) {
      toast.error(CATCH_MESSAGE);
      setLoading(false);
    }
  };

  const selectAllHotels = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      let allHotelCodes = compsetList.map((item) => item.hotelcode);
      setRemove_hotel(allHotelCodes);
    } else {
      remove_hotel.length === compsetList.length
        ? setRemove_hotel([])
        : setRemove_hotel(remove_hotel);
    }
  };

  //For Remove hotels
  const handleHotelCode = (
    e: React.ChangeEvent<HTMLInputElement>,
    hotelcode: any
  ) => {
    let newArray = [...remove_hotel];
    if (e.target.checked) {
      newArray.push(hotelcode);
    } else {
      newArray = newArray.filter((selected_hotelcode) => {
        return selected_hotelcode !== hotelcode;
      });
    }

    setRemove_hotel(newArray);
  };

  // To Remove Compititor

  const deleteFile = async () => {
    try {
      let deleteRes = await rateShopperEndPoint.post(`/removecompsethotel`, {
        hotel_id: hotel_id,
        compset_hotel_code: remove_hotel,
        admin_id: admin_id,
      });

      if (deleteRes.data.status === 1) {
        setAlertRes(false);
        setRemove_hotel([]);
        getCompsetDetails();
        toast.success(deleteRes.data.message);
      } else {
        setAlertRes(false);
        setRemove_hotel([]);
        getCompsetDetails();
        toast.error(deleteRes.data.message);
      }
    } catch {
      toast.error(CATCH_MESSAGE);
      setAlertRes(false);
    }
  };

  const setAlertRes = (val: boolean) => {
    if (val) {
      deleteFile();
    }
  };

  return (
    <>
      {/* Breadcrumbs */}
      <div className="compset__breadcrumbs__wrapper">
        <CustomBreadcrumbs content={[{ label: "Compset" }]} />
        <div>
          <Button
            icon="bi bi-plus-lg"
            handleClick={() => {
              setAddSliderOpen(true);
            }}
          >
            Add
          </Button>
        </div>
      </div>
      <hr />
      {/* Deletion of Selected */}
      <div className="selected__delete__wrapper">
        <div>{remove_hotel?.length} Selected</div>
        <div
          className="remove__btn"
          onClick={() => {
            if (remove_hotel.length > 0) {
              setHotel_remove_alert(true);
            } else {
              toast.error("Not Selected Any");
            }
          }}
        >
          <i className="bi bi-trash-fill"></i>
          Remove
        </div>
      </div>
      {loading ? (
        <LoaderOverlay />
      ) : (
        <Card className="compset_table_card">
          {compsetList.length > 0 ? (
            <table className="table table-bordered">
              <thead className="compset_table_head">
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      id="all"
                      className="form-check-input me-3"
                      // value={compsetList}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        selectAllHotels(e)
                      }
                      checked={remove_hotel.length === compsetList.length}
                    />
                    <label htmlFor="all">All</label>
                  </th>
                  {tableHeadData?.map((iteam: string) => {
                    return <th scope="col">{iteam}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {compsetList
                  ?.slice(pagesvisited, pagesvisited + hotelsperpage)
                  ?.map((iteam: any) => {
                    return (
                      <tr
                        key={iteam?.hotelcode}
                        className="compset_table_body_row"
                      >
                        <td>
                          <input
                            type="checkbox"
                            className="form-check-input me-2"
                            value={iteam.hotelcode}
                            checked={remove_hotel.includes(iteam.hotelcode)}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => handleHotelCode(e, iteam.hotelcode)}
                          />
                        </td>
                        <td>{iteam?.hotelname}</td>
                        <td>{iteam?.address}</td>
                        <td>{iteam?.zip}</td>
                        <td>{iteam?.city}</td>
                        <td>{iteam?.state}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          ) : (
            <div className="no_data_found">No Data Found 😥</div>
          )}
          <div className="pagination__wrapper">
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousbtn"}
              nextLinkClassName={"nextbtn"}
              disabledClassName={"paginationdisabled"}
              activeClassName={"paginationactive"}
            />
          </div>
        </Card>
      )}
      {/* Sliding Pane For Add New */}
      <SlidingPane
        hideHeader
        from="right"
        width="60%"
        isOpen={addSliderOpen}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => {
          setAddSliderOpen(false);
        }}
      >
        <AddCompsetSlider
          onClose={() => {
            setAddSliderOpen(false);
          }}
          getCompsetDetails={getCompsetDetails}
        />
      </SlidingPane>
      {/* Alert For Remove */}

      <AnimatePresence
        key={"alert"}
        initial={false}
        exitBeforeEnter={true}
        onExitComplete={() => null}
      >
        {hotel_remove_alert && (
          <Alert
            onOpen={hotel_remove_alert}
            alertMsg={"Are You Sure Want To Remove Selected ?"}
            btnLeft={"Yes"}
            btnRight={"No"}
            Close={() => setHotel_remove_alert(false)}
            onRes={(val) => setAlertRes(val)}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default Compset;

import { DIRECT_LOGIN, LOGIN_CREDENTAILS, LOGIN_SUCCESS } from "../actionTypes";

const initialState = {
  admin_id: null,
  auth_token: null,
  company_id: null,
  email: null,
  password: null,
  full_name: null,
  subscription_customer_id: null,
  hotel_id: null,
  hotel_name: null,
  directLogin: null,
};

const LoginReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,

        admin_id: action.payload.admin_id,
        auth_token: action.payload.auth_token,
        company_id: action.payload.company_id,
        full_name: action.payload.full_name,
        hotel_id: action.payload.hotel_id,
        hotel_name: action.payload.hotel_name,
      };
    case LOGIN_CREDENTAILS:
      return {
        ...state,
        email: action.payload.email,
        password: action.payload.password,
      };
    case DIRECT_LOGIN:
      return {
        ...state,
        directLogin: action.payload,
      };
    default:
      return state;
  }
};

export default LoginReducer;

import { motion } from "framer-motion";
import React from "react";
import ReactDOM from "react-dom";
import Card from "../card/Card";
export interface IModalProps {
  children: React.ReactNode;
  isShowing: boolean;
  hide: any;
}

const Modal: React.FC<IModalProps> = ({ children, isShowing, hide }) => {
  const dropIn = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: {
        duration: 0.1,
        type: "spring",
        damping: 25,
        stiffness: 500,
      },
    },
    exit: {
      y: "100vh",
      opacity: 0,
    },
  };
  return isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div className="modal-overlay"></div>
          <motion.div
            onClick={(e) => e.stopPropagation()}
            className="modal-wrapper"
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
            variants={dropIn}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            {/* <div className="modal">
              <div className="modal-header">
                <button
                  type="button"
                  className="modal-close-button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={hide}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              {children}
            </div> */}
            {children}
          </motion.div>
        </React.Fragment>,
        document.body
      )
    : null;
};

export default Modal;

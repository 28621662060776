import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import rateShopperEndPoint from "../../Apis/rateShopperEndPoint";
import Button from "../../Components/buttons/Button";
import InputSelectField from "../../Components/inputtextfield/InputSelectField";
import LoaderOverlay from "../../Components/loader/LoaderOverlay";
import { RootState } from "../../Redux/store";
export interface IRoomMappingProps {
  standardizeList: any[];
}

const RoomMapping: React.FC<IRoomMappingProps> = ({ standardizeList }) => {
  const { admin_id, auth_token, hotel_id } = useSelector(
    (state: RootState) => state.auth
  );

  const [pagenumber, setPagenumber] = useState<number>(0);

  const [selectedStandradizeRoom, setSelectedStandradizeRoom] = useState<any>(
    standardizeList[0]?.id ?? ""
  );
  // const [channelRoomTypeRes, setChannelRoomTypeRes] = useState<any>({});
  const [tableHeadData, setTableHeadData] = useState<any[]>([]);
  const [tableBodyData, setTableBodyData] = useState<any[]>([]);
  const [loader, setLoader] = useState<boolean>(false);

  const hotelsperpage = 13;
  const pagesvisited = pagenumber * hotelsperpage;
  const pageCount = Math.ceil(
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0].length / hotelsperpage
  );
  const changePage = (props: any) => {
    setPagenumber(props.selected);
  };
  useEffect(() => {
    if (selectedStandradizeRoom) {
      getChannelRoomMapping();
    }
  }, [selectedStandradizeRoom]);

  // Api To Get Room Mapping Deatils
  const getChannelRoomMapping = async () => {
    setLoader(true);
    try {
      let channelRoomMappingresponse = await rateShopperEndPoint.get(
        `/getchannelroomtypes/${hotel_id}/${selectedStandradizeRoom}`
      );
      if (channelRoomMappingresponse.data.status === 1) {
        // setChannelRoomTypeRes(channelRoomMappingresponse.data);
        setTableHeadData(channelRoomMappingresponse.data.source_data);
        setTableBodyData(channelRoomMappingresponse.data.mapped_room_types);
        setLoader(false);
      } else {
        // setChannelRoomTypeRes({});
        setTableHeadData([]);
        setTableBodyData([]);
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  // DropDownHandler Function
  const dropDownHandler = (event: any, hotel_name: any, source_id: any) => {
    const modArr = tableBodyData[hotel_name][source_id].map((val: any) => {
      if (val.room_type === event.target.value) {
        return {
          ...val,
          is_mapped: true,
        };
      } else {
        return {
          ...val,
          is_mapped: false,
        };
      }
    });
    const newLi = { ...tableBodyData[hotel_name], [source_id]: modArr };
    const newTableBody = { ...tableBodyData, [hotel_name]: newLi };
    setTableBodyData(newTableBody);
  };
  // console.log("channelRoomTypeRes", channelRoomTypeRes);
  // console.log("tableBodyData", tableBodyData);

  return (
    <div className="room_mapping_wrapper">
      <div className="room_mapping_dropdown mb-2">
        <InputSelectField
          label={"Choose Standardize Room"}
          value={selectedStandradizeRoom}
          options={standardizeList.map((iteam) => iteam.standardized_room_type)}
          values={standardizeList.map((iteam) => iteam.id)}
          handleChange={(event) =>
            setSelectedStandradizeRoom(event.target.value)
          }
        />
      </div>
      {loader ? (
        <LoaderOverlay />
      ) : tableHeadData?.length > 0 ? (
        <>
          <table className="table table-bordered">
            <thead className="roomtype_table_head">
              <tr>
                <th className="comp_th">Competitors</th>
                {tableHeadData?.map((iteam: any) => {
                  return (
                    <th
                      scope="col"
                      key={iteam.source_id}
                      style={{ minWidth: "150px" }}
                    >
                      {iteam.source_name}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {Object.keys(tableBodyData)
                // ?.slice(pagesvisited, pagesvisited + hotelsperpage)
                ?.map((iteam: any, idx) => {
                  return (
                    <tr key={idx} className="roomtype_table_body_row">
                      <td>{iteam}</td>
                      {tableHeadData?.map((itm: any, index) => {
                        const val =
                          tableBodyData[iteam][itm.source_id]?.filter(
                            (val: any) => val.is_mapped === true
                          )[0]?.room_type ?? "Not Mapped";
                        const optnArr = ["Not Mapped"];
                        tableBodyData[iteam][itm.source_id]?.map((val: any) =>
                          optnArr.push(val.room_type)
                        );

                        return (
                          <td
                            key={index}
                            className="mui__input__fieldset__legends__unset"
                          >
                            <InputSelectField
                              label={""}
                              value={val}
                              options={optnArr}
                              handleChange={(e: any) =>
                                dropDownHandler(e, iteam, itm.source_id)
                              }
                              disabledOptions={[0]}
                            />
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <div className="roomtype_save_btn_wrapper">
            <Button>Save</Button>
          </div>
        </>
      ) : tableHeadData?.length === 0 && selectedStandradizeRoom.length > 0 ? (
        <div className="no_data_found">No Data Found 😥</div>
      ) : (
        <div className="no_data_found">Select Standardize Room </div>
      )}

      {/* <div className="pagination__wrapper">
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousbtn"}
          nextLinkClassName={"nextbtn"}
          disabledClassName={"paginationdisabled"}
          activeClassName={"paginationactive"}
        />
      </div> */}
    </div>
  );
};

export default RoomMapping;

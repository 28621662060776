import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import rateShopperEndPoint from "../../Apis/rateShopperEndPoint";
import Button from "../../Components/buttons/Button";
import Card from "../../Components/card/Card";
import { RootState } from "../../Redux/store";
import { CATCH_MESSAGE } from "../../UtilityFunctions";
export interface IAddSourcesSliderProps {
  onClose: () => void;
  getAllSources: () => void;
  addedSources: any[];
}

const AddSourcesSlider: React.FC<IAddSourcesSliderProps> = ({
  onClose,
  getAllSources,
  addedSources,
}) => {
  const { admin_id, auth_token, hotel_id } = useSelector(
    (state: RootState) => state.auth
  );
  const [loader, setLoader] = useState<boolean>(false);
  const [sourcesData, setSourcesData] = useState<any[]>([]);
  const [selectedota, setSelectedota] = useState<any[]>([]);
  const [select_all_ota, setSelect_all_ota] = useState<boolean>(false);
  useEffect(() => {
    getSearchSources();
  }, []);

  useEffect(() => {
    const addedOta = addedSources.map((item: any) => item.websitecode);
    setSelectedota(addedOta);
  }, [addedSources]);
  // Api Call To Get Search Sources
  const getSearchSources = async () => {
    try {
      let sourcesResponse = await rateShopperEndPoint.get(`/searchsources`);

      if (sourcesResponse?.data?.status === 1) {
        setSourcesData(sourcesResponse?.data?.source_list);
      } else {
        setSourcesData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addAll_selected_ota = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      let allOtaCodes = sourcesData.map((item: any) => item.websitecode);
      setSelectedota(allOtaCodes);
    } else {
      selectedota.length === sourcesData.length
        ? setSelectedota([])
        : setSelectedota(selectedota);
    }
  };

  const handleCheckboxOtaChange = (e: any, selected_ota: any) => {
    let newArray = [...selectedota];
    let newArray_otacodes = [];
    if (e.target.checked) {
      newArray.push(selected_ota);
    } else {
      newArray = newArray.filter((ota) => {
        return ota !== selected_ota;
      });
    }

    setSelectedota(newArray);
  };

  // Post Api To Add Add Sources
  const addSelectedOta = async () => {
    setLoader(true);
    try {
      let addResp = await rateShopperEndPoint.post(`/addsource`, {
        hotel_id: hotel_id,
        source_codes: selectedota.toString(),
        admin_id: admin_id,
      });

      if (addResp.data.status === 1) {
        toast.success(addResp.data.message);
        setLoader(false);
        setSelectedota([]);
        getAllSources();
        onClose();
      } else {
        toast.error(addResp.data.message);
        setLoader(false);
        setSelectedota([]);
        getAllSources();
        onClose();
      }
    } catch {
      toast.error(CATCH_MESSAGE);
      setLoader(false);
    }
  };
  return (
    <>
      <div className="sliding-pane-header">
        <div className="slider-close-btn" onClick={onClose}>
          <i className="bi bi-x-circle"></i>
        </div>
        <div>Add Sources</div>
      </div>
      <div className="compitior-slider-body">
        <div className="sources_add_selected_wrapper">
          <div className="selected--number-container">
            {selectedota.length} Selected
          </div>
          <div className="ms-4">
            <Button handleClick={() => addSelectedOta()} loader={loader}>
              Add
            </Button>
          </div>
        </div>

        <Card className="sources-list-card">
          <div className="sources-list-card-header">
            <div className="checkbox__wrapper">
              <input
                type="checkbox"
                id="all"
                className="form-check-input me-3"
                checked={selectedota.length === sourcesData.length}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  addAll_selected_ota(e)
                }
              />
            </div>
            <div className="sources-head">Sources</div>
          </div>
          {sourcesData.length > 0 ? (
            sourcesData?.map((iteam: any, index: number) => {
              return (
                <div className="sources-list-card-body" key={iteam.websitecode}>
                  <div className="checkbox__wrapper">
                    <input
                      type="checkbox"
                      id={iteam.websitename}
                      value={iteam.websitename}
                      onChange={(e) =>
                        handleCheckboxOtaChange(e, iteam.websitecode)
                      }
                      checked={selectedota.includes(iteam.websitecode)}
                    />
                  </div>
                  <div className="sources-head">
                    <img
                      src={iteam?.logo_url}
                      alt={iteam.websitename}
                      style={{ height: "30px" }}
                    />
                  </div>
                </div>
              );
            })
          ) : (
            <></>
          )}
        </Card>
      </div>
    </>
  );
};

export default AddSourcesSlider;

import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import CustomBreadcrumbs from "../../Components/breadcrumbs/CustomBreadcrumbs";
import Button from "../../Components/buttons/Button";
import Card from "../../Components/card/Card";
import InputSelectField from "../../Components/inputtextfield/InputSelectField";
import RoomMapping from "./RoomMapping";
import StandardizeRoom from "./StandardizeRoom";
import SlidingPane from "react-sliding-pane";
import AddStandardRoom from "./AddStandardRoom";
import rateShopperEndPoint from "../../Apis/rateShopperEndPoint";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import { toast } from "react-toastify";
import { CATCH_MESSAGE } from "../../UtilityFunctions";
const roomTypesMangeTabs = [
  {
    id: "mapping-tab",
    target: "mapping",
    label: "Mapping",
  },
  {
    id: "define-tab",
    target: "define",
    label: "Define",
  },
];

const RoomType = () => {
  const { admin_id, auth_token, hotel_id } = useSelector(
    (state: RootState) => state.auth
  );
  const [tabTarget, setTabTarget] = useState<any>("mapping");
  const [addSliderOpen, setAddSliderOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [standardizeList, setStandradizeList] = useState<any[]>([]);

  useEffect(() => {
    getStandardizeRoomDetails();
  }, []);
  // Get Standardize Room Details Details
  const getStandardizeRoomDetails = async () => {
    setLoading(true);
    try {
      let standardIzeRoomData = await rateShopperEndPoint.get(
        `/getstandardizedroomtypes/${hotel_id}`
      );

      if (standardIzeRoomData.data.status === 1) {
        setStandradizeList(standardIzeRoomData?.data?.room_types);
        setLoading(false);
      } else {
        setStandradizeList([]);
        setLoading(false);
      }
    } catch (error) {
      toast.error(CATCH_MESSAGE);
      setLoading(false);
    }
  };

  return (
    <>
      <div className="roomtype__breadcrumbs__wrapper">
        <CustomBreadcrumbs content={[{ label: "Room Types" }]} />

        <div className="roomtype_tab_div mb-2">
          <ul className="roomtype_tab_ul">
            {roomTypesMangeTabs.map((iteam, idx) => {
              return (
                <li
                  key={idx}
                  className={`roomtype_tab_li ${
                    tabTarget === iteam.target ? "roomtype_tab_active_li" : ""
                  }`}
                >
                  <button
                    onClick={() => setTabTarget(iteam.target)}
                    className={`roomtype_tab_btn ${
                      tabTarget === iteam.target
                        ? "roomtype_tab_active_btn"
                        : ""
                    }`}
                  >
                    {iteam.label}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <hr />
      <div className="roomtype_table_wrapper">
        <Card className="roomtype_table_card">
          <div className="roomtype_tab_content mb-4">
            {tabTarget === "define" ? (
              <StandardizeRoom
                getStandardizeRoomDetails={getStandardizeRoomDetails}
                standardizeList={standardizeList}
                loading={loading}
              />
            ) : (
              <RoomMapping standardizeList={standardizeList} />
            )}
          </div>
        </Card>
      </div>
    </>
  );
};

export default RoomType;

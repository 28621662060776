import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { browserName } from "react-device-detect";

import { useDispatch } from "react-redux";
import {
  LoginSuccess,
  LoginCredentails,
} from "../../Redux/Actions/LoginAction";

import StandardButton from "../../Components/buttons/Button";
import Loader from "../../Components/loader/Loader";

import { validateEmail, validateMobile } from "../../UtilityFunctions";
import InputTextField from "../../Components/inputtextfield/InputTextField";
import InputPasswordField from "../../Components/inputtextfield/InputPasswordField";

import { toast } from "react-toastify";
import kernelApi from "../../Apis/kernelApi";
import LeftSideNav from "../../Components/onboarding/LeftSidenav";
import Lottie from "react-lottie";
import animationData from "../../Assets/lottie/login-lottie.json";
import FlashingLoader from "../../Components/loader/FlashingLoader";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [emailHelperText, setEmailHelperText] = useState("");
  const [passwordHelperText, setPasswordHelperText] = useState("");

  const [isloading, setIsLoading] = useState(false);

  const EmailOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    if (
      validateEmail(e.target.value) ||
      validateMobile(e.target.value) ||
      !e.target.value
    ) {
      setEmailHelperText("");
    } else {
      setEmailHelperText("Invalid email or mobile");
    }
  };

  const PasswordOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    if (e.target.value.length < 4 && e.target.value) {
      setPasswordHelperText("Invalid password");
    } else {
      setPasswordHelperText(" ");
    }
  };

  const LoginFormSubmit = async () => {
    if (!email) {
      toast("Enter your email or mobile", {
        hideProgressBar: true,
        type: "error",
      });
    } else if (!password) {
      toast("Please enter your password", {
        hideProgressBar: true,
        type: "error",
      });
    } else {
      setIsLoading(true);
      let response = await kernelApi.post("/admin/auth", {
        email: email,
        password: password,
        browser: browserName,
        creation_mode: "WEBSITE",
      });

      setTimeout(() => {
        let credentails_data = { email: email, password: password };
        if (response.data.status === 1) {
          dispatch(LoginSuccess(response.data));
          dispatch(LoginCredentails(credentails_data));
          // dispatch(SaveSignupInfo(response.data.subscription_customer_id))

          navigate("/");
        } else if (response.data.status === 2) {
          toast(response.data.message, {
            hideProgressBar: true,
            type: "error",
          });
        } else {
          toast("Invalid Credentials", {
            hideProgressBar: true,
            type: "error",
          });
        }
        setEmail("");
        setPassword("");
        setIsLoading(false);
      }, 600);
    }
  };

  const onKeyEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      LoginFormSubmit();
    }
  };

  // For Lottie
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="div-page-wrapper">
      <div className="div__wrapper">
        <div className="div__wrapper__lottie">
          <Lottie options={defaultOptions} />
        </div>
        <div className="log__in_form__wrapper">
          <div className="div__inputs">
            {!isloading ? (
              <>
                <div className="div--head--label">Log in</div>

                <div className="div__inputs__wrapp">
                  <div className="div--email--input">
                    <InputTextField
                      value={email}
                      helperText={emailHelperText}
                      label="Email or Mobile"
                      handleChange={EmailOnChange}
                      onKeyPress={onKeyEnter}
                      handleFocus={(e) => {
                        if (!e.target.value) setEmailHelperText("");
                      }}
                    />
                  </div>

                  <div className="div--password--input">
                    <InputPasswordField
                      value={password}
                      helperText={passwordHelperText}
                      onEnterKey={onKeyEnter}
                      handleFocus={(e) => {
                        if (!e.target.value) setPasswordHelperText("");
                      }}
                      handleChange={PasswordOnChange}
                    />
                  </div>

                  <div className="__btn__wrapper text-end">
                    <StandardButton
                      className="login--btn"
                      handleClick={() => LoginFormSubmit()}
                    >
                      Login
                    </StandardButton>
                  </div>
                  <div>Version : 1.2.1</div>
                </div>
              </>
            ) : (
              <>
                <div className="div--head--label">Logging you in..</div>
                <div className="div--helper--text">
                  Please wait while we are logging you in
                  <FlashingLoader />
                </div>
                {/* <Loader /> */}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

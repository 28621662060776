import { DASHBOARD_MODAL_DATE } from "../actionTypes";

const initialState = {
  full_date: null,
};

const DashboardModalReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case DASHBOARD_MODAL_DATE:
      return {
        ...state,
        full_date: action.payload,
      };

    default:
      return state;
  }
};

export default DashboardModalReducer;

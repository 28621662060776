import {
  CANCEL_CONFIRMATION_PROMPT,
  DISABLE_SWITCH_PROPERTY_SCREEN,
  ENABLE_CONFIRMATION_PROMPT,
  ENABLE_SWITCH_PROPERTY_SCREEN,
} from "../actionTypes";

const initialState = {
  isOpen: false,
  message: "",
  action: "",
  onAccept: () => {},
  isSwitchingProperty: false,
  buttons: [],
};

const PromptReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ENABLE_CONFIRMATION_PROMPT:
      return {
        ...state,
        message: action.message,
        action: action.action,
        onAccept: action.onAccept,
        buttons: action.buttons,
        isOpen: true,
      };
    case CANCEL_CONFIRMATION_PROMPT:
      return initialState;
    case ENABLE_SWITCH_PROPERTY_SCREEN: {
      return {
        ...state,
        isSwitchingProperty: true,
      };
    }
    case DISABLE_SWITCH_PROPERTY_SCREEN: {
      return {
        ...state,
        isSwitchingProperty: false,
      };
    }
    default:
      return state;
  }
};

export default PromptReducer;

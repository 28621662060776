import React, { useEffect, useState } from "react";
import ReactSlidingPane from "react-sliding-pane";
import CustomBreadcrumbs from "../../Components/breadcrumbs/CustomBreadcrumbs";
import Button from "../../Components/buttons/Button";
import Card from "../../Components/card/Card";
import SlidingPane from "react-sliding-pane";
import AddSourcesSlider from "./AddSourcesSlider";
import rateShopperEndPoint from "../../Apis/rateShopperEndPoint";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import { toast } from "react-toastify";
import { CATCH_MESSAGE } from "../../UtilityFunctions";
import ReactPaginate from "react-paginate";
import Alert from "../../Components/alert/Alert";
import { AnimatePresence } from "framer-motion";
import LoaderOverlay from "../../Components/loader/LoaderOverlay";

const Sources = () => {
  const { admin_id, auth_token, hotel_id } = useSelector(
    (state: RootState) => state.auth
  );
  const [loader, setLoader] = useState<boolean>(false);
  const [addSliderOpen, setAddSliderOpen] = useState<boolean>(false);
  const [allResources, setAllResources] = useState<any[]>([]);
  const [remove_ota, setRemove_ota] = useState<any[]>([]);
  const [selectAllOta, SetSelectAllOta] = useState<boolean>(false);
  const [selectedOta, setSelectedOta] = useState<any[]>([]);
  const [source_remove_alert, setSource_remove_alert] = useState(false);
  const [pagenumber, setPagenumber] = useState<number>(0);
  const hotelsperpage = 13;
  const pagesvisited = pagenumber * hotelsperpage;
  const pageCount = Math.ceil(allResources.length / hotelsperpage);
  const changePage = (props: any) => {
    setPagenumber(props.selected);
  };

  useEffect(() => {
    getAllSources();
  }, []);

  // To Get All Sources
  const getAllSources = async () => {
    setLoader(true);
    try {
      let resourcesRes = await rateShopperEndPoint.get(
        `/getsources/${hotel_id}`
      );

      if (resourcesRes.data.status === 1) {
        setAllResources(resourcesRes.data.source_list);
        setLoader(false);
      } else {
        setAllResources([]);
        setLoader(false);
      }
    } catch {
      setLoader(false);
    }
  };
  //
  const select_All_Ota = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      let allOtaCodes = allResources.map((item: any) => item.websitecode);
      setRemove_ota(allOtaCodes);
    } else {
      remove_ota.length === allResources.length
        ? setRemove_ota([])
        : setRemove_ota(remove_ota);
    }
  };

  //
  const handleWebsiteCode = (
    e: React.ChangeEvent<HTMLInputElement>,
    websitecode: any
  ) => {
    let newArray = [...remove_ota];
    if (e.target.checked) {
      newArray.push(websitecode);
    } else {
      newArray = newArray.filter((selected_websitecode) => {
        return selected_websitecode !== websitecode;
      });
    }
    setRemove_ota(newArray);
  };

  // Remove Sources
  const deleteResources = async () => {
    try {
      let deleteResponse = await rateShopperEndPoint.post(`/removesource`, {
        hotel_id: hotel_id,
        source_code: remove_ota,
        admin_id: admin_id,
      });
      if (deleteResponse.data.status === 1) {
        toast.success(deleteResponse.data.message);
        setRemove_ota([]);
        getAllSources();
      } else {
        toast.error(deleteResponse.data.message);
        setRemove_ota([]);
        getAllSources();
      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };

  const setAlertRes = (val: boolean) => {
    if (val) {
      deleteResources();
    }
  };
  return (
    <>
      {/* Breadcrumbs */}
      <div className="compset__breadcrumbs__wrapper">
        <CustomBreadcrumbs content={[{ label: "Sources" }]} />
        <div>
          <Button
            icon="bi bi-plus-lg"
            handleClick={() => {
              setAddSliderOpen(true);
            }}
          >
            Add
          </Button>
        </div>
      </div>
      <hr />
      {/* Deletion of Selected */}
      <div className="selected__delete__wrapper">
        <div>{remove_ota.length} Selected</div>
        <div
          className="remove__btn"
          onClick={() => {
            if (remove_ota.length > 0) {
              setSource_remove_alert(true);
            } else {
              toast.error("Not Selected Any");
            }
          }}
        >
          <i className="bi bi-trash-fill"></i>
          Remove
        </div>
      </div>
      {/* Sources List Card */}
      {loader ? (
        <LoaderOverlay />
      ) : allResources.length > 0 ? (
        <Card className="sources-list-card">
          <div className="sources-list-card-header">
            <div className="checkbox__wrapper">
              <input
                type="checkbox"
                id="all"
                className="form-check-input me-3"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  select_All_Ota(e)
                }
                checked={remove_ota.length === allResources.length}
              />
            </div>
            <div className="sources-head">Sources</div>
          </div>
          {allResources
            ?.slice(pagesvisited, pagesvisited + hotelsperpage)
            ?.map((iteam: any, index: number) => {
              return (
                <div className="sources-list-card-body" key={iteam.websitecode}>
                  <div className="checkbox__wrapper">
                    <input
                      type="checkbox"
                      id="all"
                      className="form-check-input me-3"
                      value={iteam.websitecode}
                      checked={remove_ota.includes(iteam.websitecode)}
                      onChange={(e) => handleWebsiteCode(e, iteam.websitecode)}
                    />
                  </div>
                  {/* <div className="sources-head">{iteam.websitename}</div> */}
                  <div className="sources-head">
                    <img
                      src={iteam?.logo_url}
                      alt={iteam.websitename}
                      style={{ height: "30px" }}
                    />
                  </div>
                </div>
              );
            })}
          <div className="pagination__wrapper">
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousbtn"}
              nextLinkClassName={"nextbtn"}
              disabledClassName={"paginationdisabled"}
              activeClassName={"paginationactive"}
            />
          </div>
        </Card>
      ) : (
        <div className="no_data_found">No Data Found 😥</div>
      )}

      {/* Sliding Pane For Add New */}
      <SlidingPane
        hideHeader
        from="right"
        width="40%"
        isOpen={addSliderOpen}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => {
          setAddSliderOpen(false);
        }}
      >
        <AddSourcesSlider
          onClose={() => {
            setAddSliderOpen(false);
          }}
          getAllSources={getAllSources}
          addedSources={allResources}
        />
      </SlidingPane>
      {/* Alert For Remove */}
      <AnimatePresence
        key={"alert"}
        initial={false}
        exitBeforeEnter={true}
        onExitComplete={() => null}
      >
        {source_remove_alert && (
          <Alert
            onOpen={source_remove_alert}
            alertMsg={"Are You Sure Want To Remove Selected ?"}
            btnLeft={"Yes"}
            btnRight={"No"}
            Close={() => setSource_remove_alert(false)}
            onRes={(val) => setAlertRes(val)}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default Sources;

import React from "react";
import { useDispatch } from "react-redux";

import Button from "../buttons/Button";
import Card from "../card/Card";
import { CSSTransition } from "react-transition-group";
import { motion } from "framer-motion";
import BackDrop from "./BackDrop";

export const useAlert = () => {};
export interface IAlertProps {
  onOpen: boolean;
  alertMsg: string;
  btnLeft: string;
  btnRight: string;
  Close: () => void;
  onRes: (x: boolean) => void;
}

const Alert: React.FC<IAlertProps> = ({
  onOpen,
  alertMsg,
  btnLeft,
  btnRight,
  Close,
  onRes,
}) => {
  const btnLeftHandler = () => {
    onRes(true);
    Close();
  };
  const btnRightHandler = () => {
    onRes(false);
    Close();
  };

  const dropIn = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: {
        duration: 0.1,
        type: "spring",
        damping: 30,
        stiffness: 500,
      },
    },
    exit: {
      y: "100vh",
      opacity: 0,
    },
  };

  return (
    <>
      <BackDrop onClick={Close}>
        <motion.div
          onClick={(e) => e.stopPropagation()}
          className="custommodal"
          variants={dropIn}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <Card className="alert__card">
            <div className="alert__icon__wrapper">
              <i className="bi bi-exclamation-circle"></i>
            </div>
            <div className="alert__message">{alertMsg}</div>
            <div className="alert__btn__wrapper">
              <div className="alert__btn__left">
                <button type="button" onClick={() => btnLeftHandler()}>
                  {btnLeft}
                </button>
              </div>
              <div className="alert__btn__right">
                <button type="button" onClick={() => btnRightHandler()}>
                  {btnRight}
                </button>
              </div>
            </div>
          </Card>
        </motion.div>
      </BackDrop>
    </>
  );
};

export default Alert;

import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import rateShopperEndPoint from "../../Apis/rateShopperEndPoint";
import Button from "../../Components/buttons/Button";
import LoaderOverlay from "../../Components/loader/LoaderOverlay";
import { RootState } from "../../Redux/store";
import { CATCH_MESSAGE } from "../../UtilityFunctions";
import SlidingPane from "react-sliding-pane";
import EditStandardRoom from "./EditStandardRoom";
import Alert from "../../Components/alert/Alert";
import { AnimatePresence } from "framer-motion";
import AddStandardRoom from "./AddStandardRoom";
export interface IStandardizeRoomProps {
  standardizeList: any[];
  getStandardizeRoomDetails: () => void;
  loading: boolean;
}

const StandardizeRoom: React.FC<IStandardizeRoomProps> = ({
  standardizeList,
  getStandardizeRoomDetails,
  loading,
}) => {
  const { admin_id, auth_token, hotel_id } = useSelector(
    (state: RootState) => state.auth
  );

  const [removeRoomIds, setRemoveRoomIds] = useState<any[]>([]);
  const [roomRemoveAlert, setRoomRemoveAlert] = useState<boolean>(false);
  const [addSliderOpen, setAddSliderOpen] = useState<boolean>(false);
  const [editSlider, setEditSlider] = useState<boolean>(false);
  const [editRoomData, setEditRoomData] = useState<any>();

  const [pagenumber, setPagenumber] = useState<number>(0);

  const hotelsperpage = 13;
  const pagesvisited = pagenumber * hotelsperpage;
  const pageCount = Math.ceil(standardizeList.length / hotelsperpage);
  const changePage = (props: any) => {
    setPagenumber(props.selected);
  };

  //   If ALL Room Checked for Remove
  const onCheckAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      let allRoomIds = standardizeList.map((item) => item.id);
      setRemoveRoomIds(allRoomIds);
    } else {
      removeRoomIds.length === standardizeList.length
        ? setRemoveRoomIds([])
        : setRemoveRoomIds(removeRoomIds);
    }
  };

  //For Remove hotels
  const onCheckRoom = (e: React.ChangeEvent<HTMLInputElement>, id: any) => {
    let newArray = [...removeRoomIds];
    if (e.target.checked) {
      newArray.push(id);
    } else {
      newArray = newArray.filter((selectedId) => {
        return selectedId !== id;
      });
    }

    setRemoveRoomIds(newArray);
  };

  //   Function to Remove Rooms
  const setAlertRes = (val: boolean) => {
    if (val) {
      deleteFile();
    }
  };
  const deleteFile = async () => {
    try {
      let deleteRes = await rateShopperEndPoint.post(
        `/removestandardizedroomtypes`,
        {
          ids: removeRoomIds ?? [],
        }
      );

      if (deleteRes.data.status === 1) {
        setAlertRes(false);
        setRemoveRoomIds([]);
        getStandardizeRoomDetails();
        toast.success(deleteRes.data.message);
      } else {
        setAlertRes(false);
        setRemoveRoomIds([]);
        getStandardizeRoomDetails();
        toast.error(deleteRes.data.message);
      }
    } catch {
      toast.error(CATCH_MESSAGE);
      setAlertRes(false);
    }
  };

  return (
    <div>
      {loading ? (
        <LoaderOverlay />
      ) : (
        <div className="standardize_room_wrapper">
          <div className="add_standard_room_btn mb-2">
            <Button
              icon="bi bi-plus-lg"
              handleClick={() => setAddSliderOpen(true)}
            >
              Add
            </Button>
          </div>
          <div className="selected__delete__wrapper mb-2">
            <div>{removeRoomIds?.length} Selected</div>
            <div
              className="remove__btn"
              onClick={() => {
                if (removeRoomIds.length > 0) {
                  setRoomRemoveAlert(true);
                } else {
                  toast.error("Not Selected Any");
                }
              }}
            >
              <i className="bi bi-trash-fill"></i>
              Remove
            </div>
          </div>
          {standardizeList.length > 0 ? (
            <table className="table table-bordered">
              <thead className="standardize_room_table_head">
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      id="all"
                      className="form-check-input me-3"
                      //   value={compsetList}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onCheckAll(e)
                      }
                      checked={removeRoomIds.length === standardizeList.length}
                    />
                    <label htmlFor="all">All</label>
                  </th>
                  <th scope="col">Standardized Room Type</th>
                </tr>
              </thead>
              <tbody>
                {standardizeList
                  ?.slice(pagesvisited, pagesvisited + hotelsperpage)
                  ?.map((iteam: any) => {
                    return (
                      <tr
                        key={iteam?.id}
                        className="standardize_room_table_body_row"
                      >
                        <td>
                          <input
                            type="checkbox"
                            className="form-check-input me-2"
                            // value={iteam.hotelcode}
                            checked={removeRoomIds.includes(iteam.id)}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => onCheckRoom(e, iteam.id)}
                          />
                        </td>
                        <td>{iteam?.standardized_room_type}</td>
                        <td>
                          <button
                            className="standardize_room_edit_button"
                            type="button"
                            onClick={() => {
                              setEditSlider(true);
                              setEditRoomData(iteam);
                            }}
                          >
                            Edit
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          ) : (
            <div className="no_data_found">No Data Found 😥</div>
          )}
          <div className="pagination__wrapper">
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousbtn"}
              nextLinkClassName={"nextbtn"}
              disabledClassName={"paginationdisabled"}
              activeClassName={"paginationactive"}
            />
          </div>
        </div>
      )}

      {/* Sliding Pane For Add New Standardrize Room */}
      <SlidingPane
        hideHeader
        from="right"
        width="30%"
        isOpen={addSliderOpen}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => {
          setAddSliderOpen(false);
        }}
      >
        <AddStandardRoom
          onClose={() => {
            setAddSliderOpen(false);
          }}
          getStandardizeRoomDetails={getStandardizeRoomDetails}
        />
      </SlidingPane>
      {/* Sliding Pane For Add New Standardrize Room */}
      <SlidingPane
        hideHeader
        from="right"
        width="30%"
        isOpen={editSlider}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => {
          setEditSlider(false);
        }}
      >
        <EditStandardRoom
          onClose={() => {
            setEditSlider(false);
          }}
          getStandardizeRoomDetails={getStandardizeRoomDetails}
          roomData={editRoomData}
        />
      </SlidingPane>
      {/* Alert For Remove */}

      <AnimatePresence
        key={"alert"}
        initial={false}
        exitBeforeEnter={true}
        onExitComplete={() => null}
      >
        {roomRemoveAlert && (
          <Alert
            onOpen={roomRemoveAlert}
            alertMsg={"Are You Sure Want To Remove Selected ?"}
            btnLeft={"Yes"}
            btnRight={"No"}
            Close={() => setRoomRemoveAlert(false)}
            onRes={(val) => setAlertRes(val)}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default StandardizeRoom;
